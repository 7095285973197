import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=4e9552c9"
import script from "./notifications.vue?vue&type=script&lang=js"
export * from "./notifications.vue?vue&type=script&lang=js"
import style0 from "./notifications.vue?vue&type=style&index=0&id=4e9552c9&prod&lang=css"
import style1 from "./notifications.vue?vue&type=style&index=1&id=4e9552c9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports