<template>
  <div>
    <div
      class="pt-4 px-3 px-md-5 text-black rounded text-left"
      :class="mobileView ? 'mt-2' : ''"
      style="height:fit-content;overflow-y:auto;margin-bottom:65px;overflow-x:hidden"
    >
      <div v-if="settingsLoaded === false" class="pt-4">
        <h3 class="primary-colour text-left font-weight-bold">
          Loading
          <i style="margin-left: 5px !important;" class="fa fa-spin fa-spinner" />
        </h3>
      </div>
      <div v-else style="text-align:left;margin-top:0px;">
        <div class="w-1/1 text-black rounded" style="overflow-y:auto;overflow-x:hidden">
          <div class="font-weight-bold py-4 flex-grow-0 primary-colour">
            Share Settings
          </div>
        </div>
      </div>
      <div ref="scrollToDiv" style="height: 1px;">
        <div v-show="settingsLoaded === true" class="grey-text text-left mb-4 mx-0">
          <p style="font-size:14px;" class="grey-text">
            Settings for Sharing Candidates and Video CVs
          </p>
        </div>
        <div v-show="settingsLoaded === true" style="font-size:0.9rem;">
          <div class="md-form form-sm text-left form-style" style="max-width:600px">
            <i class="fas fa-pen prefix float-left icon-style" />
            <mdb-input
              v-model="shareName"
              type="text"
              auto-complete="fna"
              placeholder="Sharing Name"
              style="margin-left:33px;"
              size="sm"
            />
          </div>
          <div class="md-form form-sm text-left form-style">
            <i class="fas fa-video prefix float-left icon-style" style="top: -7px;" />
            <div
              id="upload-video-intro"
              class="md-form form-sm text-left form-style"
              style="margin-left:33px"
            >
              <div id="upload-text">
                Video Intro
              </div>
            </div>
          </div>

          <b-row no-gutters class="px-3">
            <div
              v-if="sharingVideoIntro.length === 0 && !useTextInstead"
              class="rounded border-2 border-gray-400 p-4 px-5 d-flex flex-column align-items-center justify-content-center"
              style="border: 2px dotted rgb(192, 192, 192);font-size:0.9rem;font-weight:bold;cursor:pointer;height:90px"
              @click="showAddInsertVideoModal = true"
            >
              <mdb-icon
                v-if="!loading"
                icon="fas fa-file-video"
                size="md"
                class="grey-text"
              />
              <p v-if="!loading" class="grey-text">
                Add Video
              </p>
              <mdb-icon
                v-else
                icon="fa fa-pulse fa-spinner"
                size="lg"
              />
            </div>
          </b-row>

          <b-row
            no-gutters
            class="grey-text mt-2"
            style="transform:translateX(-5px)"
            @click="useTextInstead = !useTextInstead"
          >
            <mdb-input
              v-model="useTextInstead"
              type="checkbox"
              label="Use a written share intro instead?"
              size="sm"
            />
          </b-row>

          <b-row
            v-if="useTextInstead"
            no-gutters
            style="width:100%;max-width:600px;"
            class="ml-2"
          >
            <mdb-input
              v-model="introText"
              type="textarea"
              auto-complete="fna"
              :rows="4"
              size="sm"
              maxlength="500"
              aria-placeholder="Enter a brief share introduction"
              style="width:100%;"
            />
          </b-row>

          <b-row
            v-if="sharingVideoIntro.length !== 0 && !useTextInstead"
            no-gutters
            align-v="center"
            style="cursor: pointer;max-width:600px;"
            class="flex-nowrap border-2 border-gray-400 bg-gray-100 m-3 p-1 text-bold text-black rounded text-center"
          >
            <b-col cols="auto" class="flex-grow-0">
              <img
                :src="sharingVideoIntro[0].ThumbnailUrl"
                class="m-auto"
                style="height:70px; border-radius: 2px; object-fit: contain"
              >
            </b-col>
            <b-col
              offset="1"
              cols="auto"
              class="p-1 flex-grow-1"
            >
              <b-row no-gutters class="font-extrabold text-center">
                {{ sharingVideoIntro[0].ShortDescription }}
              </b-row>
              <b-row no-gutters class="flex-nowrap overflow-hidden">
                <i class="fas fa-tags prefix" style="font-size: 1rem; display:inline;transform:translateY(3px);" />
                <div
                  v-if="sharingVideoIntro[0].Tags && sharingVideoIntro[0].Tags.length > 15"
                  class="ml-2"
                  style="font-size: smaller;white-space: nowrap;"
                >
                  {{ `${sharingVideoIntro[0].Tags.substring(0, 16)}...`|| 'no tags' }}
                </div>
                <div
                  v-else
                  class="ml-2"
                  style="font-size: smaller;white-space: nowrap;"
                >
                  {{ sharingVideoIntro[0].Tags !== null && sharingVideoIntro[0].Tags !== 'null' && sharingVideoIntro[0].Tags !== undefined && sharingVideoIntro[0].Tags !== [] ? sharingVideoIntro[0].Tags : 'no tags' }}
                </div>
              </b-row>
            </b-col>
            <b-col cols="auto" class="flex-grow-0">
              <i
                style="cursor:pointer; transform:scale(1.4)"
                class="grey-text fa fa-times colorOnHover mr-3"
                @click="removeIntroVid()"
              />
            </b-col>
          </b-row>

          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Include Personal Information?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="includePersonalInfo"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <div class="ml-3">
            <b-row
              no-gutters
              class="text-black mt-2 font-small "
              :class="{'grey-text' : !includePersonalInfo }"
              align-v="center"
            >
              <b-col
                cols="auto"
                class="mr-auto"
                style="max-width:55%;font-size:0.8rem;"
              >
                Include Contact Details?
              </b-col>
              <div style="width:60px;">
                <mdb-switch
                  v-model="includeContactInfo"
                  style="transform:scale(0.9)"
                  :disabled="!includePersonalInfo"
                  off-label=""
                  on-label=""
                  class="m-0"
                />
              </div>
            </b-row>
            <b-row
              no-gutters
              class="text-black mt-2 font-small "
              align-v="center"
              :class="{'grey-text' : !includePersonalInfo }"
            >
              <b-col
                cols="auto"
                class="mr-auto"
                style="max-width:55%;font-size:0.8rem;"
              >
                Include Education Information?
              </b-col>
              <div style="width:60px;">
                <mdb-switch
                  v-model="includeEducationInfo"
                  style="transform:scale(0.9)"
                  :disabled="!includePersonalInfo"
                  off-label=""
                  on-label=""
                  class="m-0"
                />
              </div>
            </b-row>
            <b-row
              no-gutters
              class="text-black mt-2 font-small "
              align-v="center"
              :class="{'grey-text' : !includePersonalInfo }"
            >
              <b-col
                cols="auto"
                class="mr-auto"
                style="max-width:55%;font-size:0.8rem;"
              >
                Include Salary Information?
              </b-col>
              <div style="width:60px;">
                <mdb-switch
                  v-model="includeSalaryInfo"
                  style="transform:scale(0.9)"
                  :disabled="!includePersonalInfo"
                  off-label=""
                  on-label=""
                  class="m-0"
                />
              </div>
            </b-row>
          </div>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Include Bio Information?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="includeBioInfo"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Include CV?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="includeCVInfo"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Include Other Files?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="includeOtherFiles"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Include LinkedIn?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="includeLinkedIn"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Request Feedback?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="requestFeedback"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Tracking Notifications?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="enableTrackingNotifications"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <b-row
            no-gutters
            class="text-black mt-2 font-small "
            align-v="center"
          >
            <b-col
              cols="auto"
              class="mr-auto"
              style="max-width:55%;font-size:0.8rem;"
            >
              Domain Authentication?
            </b-col>
            <div style="width:60px;">
              <mdb-switch
                v-model="enableDomainAuthSharing"
                style="transform:scale(0.9)"
                off-label=""
                on-label=""
                class="m-0"
              />
            </div>
          </b-row>
          <div class="d-flex align-items-center justify-content-between" style="transform:translateY(-20px);float: right;margin-top: 20px;margin-right: -5px;">
            <mdb-btn
              v-if="settingsLoaded"
              class="float-right"
              size="sm"
              style="width:100px;margin-top:30px;"
              @click.native="saveSettings()"
            >
              SAVE
            </mdb-btn>
            <mdb-btn
              v-else
              class="float-right"
              size="sm"
              style="width:100px;margin-top:30px;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </div>
          <!--
            <mdb-btn @click.native="testGetShareDetails()" v-show="mobileView === false" class="float-right" size="sm" style="width:250px;display:none;">TEST SHARE VIEW MODEL DATA</mdb-btn>
            <mdb-btn @click.native="testGetShareDetails()" v-show="mobileView" class="float-right" size="sm" style="width:100px;display:none;">TEST SHARE</mdb-btn>
          -->
        </div>
      </div>
    </div>

    <!-- video intro modal -->
    <InsertVideoModal
      :defaultCategory="'Introduction'"
      @handleChosenVideo="handleChosenVideo"
    />
  </div>
</template>

<script>
import { useCandidate, useUserData, useLibrary } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import userService from '@/api/services/user.service'
import libraryService from '@/api/services/library.service'
import InsertVideoModal from '@/components/genericModals/InsertVideoModals/insertVideoModal.vue'

export default {
  name: 'ShareSettings',
  components: {
    InsertVideoModal
  },
    setup () {
    const candidateStore = useCandidate()
    const { sharingTextIntro, sharingVideoIntro } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    const libraryStore = useLibrary()
    const { showAddInsertVideoModal } = storeToRefs(libraryStore)
    return {
      sharingTextIntro,
      sharingVideoIntro,
      authData,
      userData,
      mobileView,
      showAddInsertVideoModal
    }
  },
  data () {
    return {
      enableTrackingNotifications: false,
      enableDomainAuthSharing: false,
      includeCVInfo: true,
      includeBioInfo: true,
      includeOtherFiles: true,
      includePersonalInfo: true,
      includeContactInfo: true,
      includeSalaryInfo: true,
      includeEducationInfo: true,
      contactInfoInitial: true,
      educationInfoInitial: true,
      salaryInfoInitial: true,
      includeLinkedIn: true,
      requestFeedback: false,
      settingsLoaded: false,
      shareName: '',
      videoModalPage: 'choose',
      loading: false,
      useTextInstead: false,
      uploadedVideoId: ''
    }
  },
  computed: {
    introText: {
      get () {
        return this.sharingTextIntro
      },
      set (value) {
        this.sharingTextIntro = value
        if (this.sharingVideoIntro.length > 0) {
          this.sharingVideoIntro = []
        }
      }
    }
  },
  watch: {
    includePersonalInfo () {
      if (this.includePersonalInfo) {
        this.includeContactInfo = this.contactInfoInitial
        this.includeSalaryInfo = this.salaryInfoInitial
        this.includeEducationInfo = this.educationInfoInitial
      } else {
        this.includeContactInfo = false
        this.includeSalaryInfo = false
        this.includeEducationInfo = false
      }
    }
  },
  created () {
    this.loadUserSettings()
  },
  beforeUnmount () {
    this.sharingVideoIntro = ''
    this.sharingTextIntro = ''
  },
  methods: {
    handleChosenVideo (vid) {
      this.uploadedVideoId = vid.UploadedVideoId
      this.showAddInsertVideoModal = false
      this.sharingVideoIntro = [vid]
      this.sharingTextIntro = ''
    },
    removeIntroVid () {
      this.sharingVideoIntro = []
      this.uploadedVideoId = null
    },
    async loadUserSettings () {
      try {
        const res = await userService.getUserSettings()
        res.data.SharingEnableDomainAuthSharing === false || res.data.SharingEnableDomainAuthSharing === undefined ? this.enableDomainAuthSharing = false : this.enableDomainAuthSharing = true
        res.data.SharingEnableTrackingNotifications === false || res.data.SharingEnableTrackingNotifications === undefined ? this.enableTrackingNotifications = false : this.enableTrackingNotifications = true
        res.data.SharingIncludeBioInfo === true || res.data.SharingIncludeBioInfo === undefined ? this.includeBioInfo = true : this.includeBioInfo = false
        res.data.SharingIncludeContactInfo === true || res.data.SharingIncludeContactInfo === undefined ? this.includeContactInfo = true : this.includeContactInfo = false
        res.data.SharingIncludeContactInfo === true || res.data.SharingIncludeContactInfo === undefined ? this.contactInfoInitial = true : this.contactInfoInitial = false
        res.data.SharingIncludeEducationInfo === true || res.data.SharingIncludeEducationInfo === undefined ? this.includeEducationInfo = true : this.includeEducationInfo = false
        res.data.SharingIncludeEducationInfo === true || res.data.SharingIncludeEducationInfo === undefined ? this.educationInfoInitial = true : this.educationInfoInitial = false
        res.data.SharingIncludeSalaryInfo === true || res.data.SharingIncludeSalaryInfo === undefined ? this.includeSalaryInfo = true : this.includeSalaryInfo = false
        res.data.SharingIncludeSalaryInfo === true || res.data.SharingIncludeSalaryInfo === undefined ? this.salaryInfoInitial = true : this.salaryInfoInitial = false
        res.data.SharingIncludeCV === true || res.data.SharingIncludeCV === undefined ? this.includeCVInfo = true : this.includeCVInfo = false
        res.data.SharingIncludeOtherFiles === true || res.data.SharingIncludeOtherFiles === undefined ? this.includeOtherFiles = true : this.includeOtherFiles = false
        res.data.SharingIncludeLinkedIn === true || res.data.SharingIncludeLinkedIn === undefined ? this.includeLinkedIn = true : this.includeLinkedIn = false
        res.data.SharingRequestFeedback === false || res.data.SharingRequestFeedback === undefined ? this.requestFeedback = false : this.requestFeedback = true
        res.data.SharingIncludePersonalInfo === true || res.data.SharingIncludePersonalInfo === undefined ? this.includePersonalInfo = true : this.includePersonalInfo = false
        this.shareName = res.data.SharingDefaultShareName
        this.introText = res.data.SharingDefaultIntroText
        this.uploadedVideoId = res.data.SharingDefaultUploadedVideoId
        if (this.uploadedVideoId !== null && this.uploadedVideoId !== undefined && this.uploadedVideoId !== 0) {
          var req = await libraryService.getVideoFromVideoId(this.uploadedVideoId, this.userData.aid)
          this.sharingVideoIntro = [req.data]
          this.sharingTextIntro = ''
        }
        if (this.introText !== '' && this.introText !== null) {
          this.useTextInstead = true
        }
      } catch (err) {
        //?
      }
      setTimeout(() => {
        this.settingsLoaded = true
        this.$parent.settingsLoaded = true
      }, 3000)
    },
    async saveSettings () {
      this.settingsLoaded = false
      const data = {
        enableDomainAuthSharing: this.enableDomainAuthSharing,
        enableTrackingNotifications: this.enableTrackingNotifications,
        includeBioInfo: this.includeBioInfo,
        includeCV: this.includeCVInfo,
        includePersonalInfo: this.includePersonalInfo,
        includeOtherFiles: this.includeOtherFiles,
        includeSalaryInfo: this.includeSalaryInfo,
        includeEducationInfo: this.includeEducationInfo,
        includeLinkedIn: this.includeLinkedIn,
        includeContactInfo: this.includeContactInfo,
        requestFeedback: this.requestFeedback,
        shareName: this.shareName,
        introText: (this.useTextInstead) ? this.introText : null,
        uploadedVideoId: (!this.useTextInstead) ? this.uploadedVideoId : null
      }
      try {
        const res = await userService.createEditUserSettings(data)
        this.enableDomainAuthSharing = res.data.SharingEnableDomainAuthSharing
        this.enableTrackingNotifications = res.data.SharingEnableTrackingNotifications
        this.includeBioInfo = res.data.SharingIncludeBioInfo
        this.includeCVInfo = res.data.SharingIncludeCV
        this.includeOtherFiles = res.data.SharingIncludeOtherFiles
        this.includeContactInfo = res.data.SharingIncludeContactInfo
        this.includeSalaryInfo = res.data.SharingIncludeSalaryInfo
        this.includeEducationInfo = res.data.SharingIncludeEducationInfo
        this.includePersonalInfo = res.data.SharingIncludePersonalInfo
        this.includeLinkedIn = res.data.SharingIncludeLinkedIn
        this.requestFeedback = res.data.SharingRequestFeedback
        this.shareName = res.data.SharingDefaultShareName
        this.introText = res.data.SharingDefaultIntroText
        this.uploadedVideoId = res.data.SharingDefaultUploadedVideoId
        if (this.uploadedVideoId !== null && this.uploadedVideoId !== undefined && this.uploadedVideoId !== 0) {
          var req = await libraryService.getVideoFromVideoId(this.uploadedVideoId, this.userData.aid)
          this.sharingVideoIntro = [req.data]
          this.sharingTextIntro = ''
        }
      } catch (err) {
        //?
      }
      this.settingsLoaded = true
    },
    async testGetShareDetails () {
      const goShareId = 8
      try {
        await userService.getCandidateShareDetails(goShareId)
      } catch (err) {
        //?
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-video-intro{
  display: flex;
}
.video-upload-container {
  max-width: 300px;
}
</style>
