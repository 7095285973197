import axiosInstance from '../instance.js'
const api1 = new axiosInstance(1)

export default class reportService {

  static async reportsSummary () {
    return await api1.get('/api/go/reportssummary')
  }

  static async getRoleReportData (roleId, inactive) {
    return await api1.get(`/api/go/downloadrolereportdata?roleId=${roleId}&inactive=${inactive}`)
  }

  static async getReportSummaryDownload (accountId, active) {
    return await api1.get(`/api/go/getreportsummarydata?accountId=${accountId}&active=${active}`)
  }

  static async getAllAccountsReportsSummaryDownload (allData) {
    return await api1.get('/api/go/getallaccountsreportssummarydownload?allData=' + allData)
  }

  static async downloadCandidateSummary () {
    return await api1.get('/api/go/downloadcandidatesummary')
  }

  static async downloadGenericSummary () {
    return await api1.get('/api/go/downloadgenericsummary')
  }

  static async getScoringReport () {
    return await api1.get('/api/go/getscoringreport')
  }

  static async getRoleScoringReport (roleId) {
    return await api1.get('/api/go/getrolescoringreport?roleId=' + roleId)
  }  

  static async downloadSurveyData (jobId) {
    return await api1.get('/api/go/downloadsurveydata?jobId=' + jobId)
  }
}
