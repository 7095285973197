import axiosInstance from '../instance.js'
const api2 = new axiosInstance(2)

export default class meetNowService {

  //.net6
  static async getMeetNowRecordings (recordingFilter) {
    return await api2.get(`/api/meeting/getmeetnowrecordings?recordingFilter=${recordingFilter}`)
  }

  static async getAllAvailableRooms () {
    return await api2.get('/api/meeting/getallavailablemeetnowrooms')
  }

  static async getMeetNowSummary () {
    return await api2.get('/api/meeting/getmeetnowsummary')
  }

  static async getAllAvailablePrivateRooms () {
    return await api2.get('/api/meeting/getallavailableprivaterooms')
  }

  static async updateDeleteGoMeetNowRoom (roomId, setDefault) {
    return await api2.post(`/api/meeting/updatedeletegomeetnowroom?roomId=${roomId}&setDefault=${setDefault}`, {})
  }
  
  static async updateGoMeetNowRecordingName (roomId, roomName) {
    return await api2.post('/api/meeting/updategomeetnowrecordingname?roomId=' + roomId + '&roomName=' + roomName, {})
  }

  static async updateMeetNowRecording (updateType, recordingId) {
    return await api2.post(`/api/meeting/updatemeetnowrecording?updateType=${updateType}&recordingId=${recordingId}`, {})
  }

  static async tagCandidateOrAssessor (formData) {
    return await api2.post('/api/meeting/tagmeetnowcandidateorassessor', formData)
  }

  static async editRoomDetails (roomId, roomName, isPublic, brandId) {
    return await api2.post('/api/meeting/editroomdetails?roomId=' + roomId + '&roomName=' + roomName + '&isPublic=' + isPublic + '&brandId=' + brandId, {})
  }

  static async searchCandidatesToTag (search) {
    return await api2.get(`/api/meeting/searchcandidatestotagmeetnow?search=${search}`)
  }

  static async getMeetNowRecording (recordingId) {
    return await api2.get(`/api/meeting/getmeetnowrecording?recordingId=${recordingId}`)
  }

  static async resetRoom (roomId) {
    return await api2.post('/api/meeting/resetroom?roomId=' + roomId, {})
  }

  static async createMeetingRoom (formData) {
    return await api2.post('/api/meeting/creategomeetingroom', formData)
  }

  static async scheduleNow (candidateId, roomId, meetingDate, meetingTime) {
    return await api2.post('/api/meeting/schedulenow?candidateId=' + candidateId + '&roomId=' + roomId + '&meetingDate=' + meetingDate + '&meetingTime=' + meetingTime, {})
  }

  static async instantMeet (candidateId, roomId) {
    return await api2.post('/api/meeting/schedulenow?candidateId=' + candidateId + '&roomId=' + roomId, {})
  }

  static async changeThumbnail (formData) {
    return await api2.post('/api/meeting/changethumbnail', formData)
  }
}
