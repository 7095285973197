<template>
  <div>
    <mdb-modal
      id="taggingUntaggingCandidateModalShowing"
      :show="taggingUntaggingCandidateModalShowing"
      size="lg"
      @close="$emit('taggingUntaggingCandidateModalShowing', false)"
    >
      <mdb-modal-header class="align-items-center justify-content-between">
        <div class="d-flex align-items-center primary-colour" style="height:26px">
          <div style="margin-right: 10px;font-weight:bold;">
            TAGS
          </div>
          <!-- HOSTS -->
          <div
            v-if="recording.Host"
            class="mr-3 d-flex align-items-center"
            style="width:fit-content;height:30px"
          >
            <img
              :title="`Host - ${recording.Host.Name}`"
              :src="recording.Host.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
            >
          </div>
          <!-- ASSESSOR -->
          <div
            v-if="recording.Assessors.length > 0"
            class="mr-3 d-flex align-items-center"
            style="width:fit-content;height:30px"
          >
            <img
              v-for="(assessor,i) in recording.Assessors"
              :key="i"
              :title="`Assessor - ${assessor.Name}`"
              :src="assessor.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
              :style="`transform:translateX(-${i * 4}px)`"
            >
          </div>
          <!-- CANDIDATES -->
          <div
            v-if="currentlyTagged.Name != ''"
            :class="newCandLoaded ? 'newCandLoadedBorder' : ''"
            class="newCand mr-3"
            :style="{'opacity': currentlyTagged.Name != '' ? '1' : '0'}"
            style="height:30px; width:fit-content;"
          >
            <img
              v-show="currentlyTagged.Name != ''"
              :title="`Candidate - ${currentlyTagged.Name}`"
              :src="currentlyTagged.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
            >
          </div>
          <!-- GUESTS -->
          <div class="mr-3 d-flex align-items-center justify-items-center" style="width:fit-content;height:30px">
            <span
              v-for="(guest,i) in recording.Guests"
              :key="i"
              :title="`Guest - ${i+1}`"
              :style="`transform:translate(-${i * 5}px, 5px)`"
              style="height:30px;"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                style="height:10px;width:10px; background:#d3d3d3;border-radius:50%;border:solid 2px;transform:scale(1.5)"
                :class="newCandLoaded ? 'newCandLoadedBorder' : ''"
                class="p-1"
              />
            </span>
          </div>
        </div>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="d-flex align-items-center justify-content-start mt-3">
          <FontAwesomeIcon icon="fa-solid fa-user-tag" class="d-inline mr-2" />
          <mdb-switch
            v-model="tagging"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0 mt-1 mr-2"
          />
          <div style="font-size:0.8rem;color:#757575">
            {{ tagging ? "Tag a Candidate" : "Untag a Candidate" }}
          </div>
        </div>
        <!-- TAGGING -->
        <div v-if="tagging" class="mt-4">
          <div v-if="!hasSearched">
            <div class="d-flex align-items-center" style="height:40px">
              <FontAwesomeIcon icon="fa-solid fa-user" class="d-inline mr-3" />
              <mdb-input
                v-model="candidateDetailsSearch"
                type="text"
                label="Search candidate name or email. Minimum 3 characters"
                size="sm"
                class="m-0 d-none d-sm-block"
                style="height:40px;width:100%;"
                @keyup.enter.native="searchCandidates"
              />
              <mdb-input
                v-model="candidateDetailsSearch"
                type="text"
                label="Name or Email. 3 characters min."
                size="sm"
                class="m-0 d-block d-sm-none"
                style="height:40px;width:100%;"
                @keyup.enter.native="searchCandidates"
              />
            </div>
            <div class="d-flex align-items-center justify-content-end mt-2" style="height:40px">
              <mdb-btn
                v-show="!buttonClick"
                class="ml-auto mt-2"
                :disabled="candidateDetailsSearch.length < 3"
                size="sm"
                style="width:100px;"
                @click.native="searchCandidates()"
              >
                SEARCH
              </mdb-btn>
              <mdb-btn
                v-show="buttonClick"
                class="ml-auto mt-2"
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </div>
          </div>
          <div v-else>
            <div v-if="searchedCandidates.length > 0 && searchedCandidates != null" style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
              <b-row
                v-for="cand in searchedCandidates"
                :key="cand.TargetId"
                align-h="between"
                align-v="center"
                no-gutters
                style="border-radius:5px;height:40px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;"
                class="p-2 m-2 borderColorOnHover"
                @click="selectedPerson = cand"
              >
                <div style="height:100%;" class="d-flex align-items-center">
                  <img
                    :src="cand.ProfilePicture"
                    style="height:25px;width:25px;border-radius:50%;object-fit:cover;border:solid;"
                    class="d-inline mr-2"
                  >
                  <h5 class="text-left font-weight-bold d-inline">
                    {{ cand.Name }}
                  </h5>
                </div>
              </b-row>
            </div>
            <div v-if="searchedCandidates.length == 0 && searchedCandidates != null" class="mt-3">
              <p class="grey-text text-center">
                No results
              </p>
            </div>
          </div>
        </div>
        <!-- UNTAGGING -->
        <div v-else>
          <div v-if="currentlyTagged.Name != ''" class="d-flex align-items-center justify-content-center mt-3">
            <div
              style="cursor:pointer;"
              class="mr-3"
            >
              <div style="box-sizing:content-box" class="d-flex flex-column align-items-center justify-content-center p-2">
                <img :src="currentlyTagged.ProfilePicture" style="width:80px; height:80px; object-fit:cover; border-radius:50%;border:solid;">
                <div class="mt-1 text-black" style="font-size:0.9rem;">
                  {{ currentlyTagged.Name }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center text-left text-black mt-5"
            style="font-size:0.9rem"
          >
            No Candidate to Untag
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer v-if="hasSearched" class="justify-content-between">
        <mdb-btn
          class="btn-outline m-0"
          size="sm"
          @click.native="searchAgain()"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-rotate"
            class="mr-2"
            size="lg"
          />SEARCH AGAIN
        </mdb-btn>
        <mdb-btn
          v-show="!buttonClick && tagging && selectedPerson != null"
          class="m-0"
          size="sm"
          style="width:100px;"
          @click.native="tagCandidateOrAssessor()"
        >
          TAG
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick && tagging && selectedPerson != null"
          class="m-0"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
      <mdb-modal-footer v-if="!tagging" class="justify-content-end">
        <mdb-btn
          v-show="!buttonClick"
          :disabled="currentlyTagged.Name == ''"
          size="sm"
          style="width:100px;"
          @click.native="tagCandidateOrAssessor()"
        >
          UNTAG
        </mdb-btn>
        <mdb-btn
          v-show="buttonClick"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useUserData, useMeetNow } from '@/pinia'
import { storeToRefs } from 'pinia'
import meetNowService from '@/api/services/meetnow.service'

export default {
  name: 'TaggingUntaggingCandidateModal',
  props: {
    recording: {
      default: () => {},
      type: Object
    },
    taggingUntaggingCandidateModalShowing: Boolean
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { addOrUpdateRecording } = useMeetNow()

    return {
      authData,
      userData,
      addOrUpdateRecording
    }
  },
  data () {
    return {
      buttonClick: false,
      loading: false,
      candidateSearchResults: [],
      candidateDetailsSearch: '',
      tagging: true,
      searchedCandidates: [],
      selectedPerson: null,
      hasSearched: false,
      newCandLoaded: false
    }
  },
  computed: {
    currentlyTagged () {
      if (this.recording.Candidate) { return this.recording.Candidate }
      return { Name: '', ProfilePicture: '' }
    }
  },
  watch: {
    tagging () {
      this.selectedPerson = null
      this.searchAgain()
    }
  },
  methods: {
    searchAgain () {
      this.selectedPerson = null
      this.hasSearched = false
      this.candidateDetailsSearch = ''
    },
    async searchCandidates () {
      this.buttonClick = true
      try {
        const res = await meetNowService.searchCandidatesToTag(this.candidateDetailsSearch)
        this.searchedCandidates = res.data
        this.hasSearched = true
      } catch (err) {
        //?
      }
      this.buttonClick = false
    },
    async tagCandidateOrAssessor () {
      this.buttonClick = true
      const formData = new FormData()
      formData.append('isCandidate', true)
      if (this.tagging) {
        formData.append('targetId', this.selectedPerson.TargetId)
      } else {
        formData.append('targetId', this.currentlyTagged.TargetId)
      }
      formData.append('recordingId', this.recording.RecordingId)
      formData.append('tagging', this.tagging)
      try {
        const res = await meetNowService.tagCandidateOrAssessor(formData)
        this.addOrUpdateRecording(res.data)
      } catch (err) {
        //?
      }
      this.tagging = true
      this.buttonClick = false
      this.newCandLoaded = true
      setTimeout(() => { this.newCandLoaded = false }, 3000)
    }
  }
}
</script>

<style lang="scss">
.newCand {
  box-shadow: 0px 0px 2px 3px var(#ffffff);
  border-radius: 50%;
  transition: all ease 1s;
}
.newCandLoadedBorder.newCand {
  box-shadow: 0px 0px 2px 3px var(--pr-color);
  border-radius: 50%;
  transition: box-shadow 1s ease 0.05s;
}
.selectedToUntagEase {
  transition: 0.5s ease;
}
.borderHover {
  border: 2px solid #fff;
}
.borderHover:hover {
  border: 2px solid var(--pr-color);
}

@media (max-width: 576px) {
  #taggingUntaggingCandidateModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 65px) !important;
  }
}
</style>
