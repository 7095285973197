<template>
  <div class="col-sm px-3 px-md-4">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div class="d-flex align-items-center">
        <span class="d-none d-sm-inline mr-2">My</span>Notifications
        <i
          v-if="notificationsToView.length == 0 && notificationsLoading"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3 d-none d-sm-block"
        />
      </div>
      <ExpandableSearchBar
        @search="search = $event"
      />
    </div>
    <!-- main list -->
    <div
      v-show="notificationsToView.length > 0"
      class="mb-3 bottomNotificationPadding"
      style="overflow-y:auto;padding-bottom:100px;"
    >
      <div
        v-for="(notif,i) in notificationsToView"
        :key="i"
        class="d-flex align-items-center px-2 py-1 px-md-3 py-md-2 mb-2"
        style="width:100%;border:1px solid #555;"
      >
        <div style="transform:translateY(3px)" @click="addOrRemoveFromChecked(notif.GoNotification_CentreId)">
          <mdb-input
            type="checkbox"
            off-label=""
            on-label=""
            :value="checkedArrayContains(notif.GoNotification_CentreId)"
            class="p-0"
          />
        </div>
        <b-row no-gutters class="mr-auto mobileWidth">
          <b-col
            v-if="notif.NotificationText.includes('failed')"
            align-self="start"
          >
            <div style="font-size:10px;color:red;font-weight:bold;" class="text-left">
              {{ notif.DateAdded.substring(0, 10) }} {{ new Date(notif.DateAdded).toLocaleTimeString([], {timeStyle: 'short', hour12: true}).toUpperCase() }} | {{ returnTypeDescriptionFromTypeId(notif.GoNotification_TypeId) }}
            </div>
            <div style="font-size:10px;color:red;" class="text-left">
              {{ notif.NotificationText }}
            </div>
          </b-col>
          <b-col
            v-else
            align-self="start"
          >
            <div style="font-size:10px;color:#333;" class="text-left">
              {{ notif.DateAdded.substring(0, 10) }} {{ new Date(notif.DateAdded).toLocaleTimeString([], {timeStyle: 'short', hour12: true}).toUpperCase() }} | {{ returnTypeDescriptionFromTypeId(notif.GoNotification_TypeId) }}
            </div>
            <div style="font-size:10px;" class="grey-text text-left">
              {{ notif.NotificationText }}
            </div>
          </b-col>
        </b-row>

        <!-- ONE WAY COMPLETION -->
        <div v-if="notif.GoNotification_TypeId === 1 && notif.RoleWorkflowStageId">
          <mdb-btn
            size="sm"
            style="width:fit-content;word-wrap:normal;"
            class="p-1 p-md-2 m-0 mr-2 deskWidth"
            @click.native="redirectToInterview(notif)"
          >
            <div class="showTxtButton">
              VIEW ONE WAY
            </div>
            <div class="showIconButton">
              <i class="fa fa-eye" />
            </div>
          </mdb-btn>
        </div>

        <!-- VIDEO CV COMPLETION -->
        <div v-if="notif.GoNotification_TypeId === 2">
          <mdb-btn
            size="sm"
            style="width:fit-content;word-wrap:normal;"
            class="p-1 p-md-2 m-0 mr-2 deskWidth"
            @click.native="doRedirectToVideoCV(notif.CandidateId)"
          >
            <div class="showTxtButton">
              VIEW VIDEO CV
            </div>
            <div class="showIconButton">
              <i class="fa fa-eye" />
            </div>
          </mdb-btn>
        </div>

        <!-- LIVE INTERVIEW COMPLETION -->
        <div v-if="(notif.GoNotification_TypeId === 3 || notif.GoNotification_TypeId === 4) && notif.RoleWorkflowStageId">
          <mdb-btn
            size="sm"
            style="width:fit-content;word-wrap:normal;"
            class="p-1 p-md-2 m-0 mr-2 deskWidth"
            @click.native="redirectToInterview(notif)"
          >
            <div class="showTxtButton">
              VIEW INTERVIEW
            </div>
            <div class="showIconButton">
              <i class="fa fa-eye" />
            </div>
          </mdb-btn>
        </div>

        <!-- ANY SHARE NOTIFICATION -->
        <!-- <div v-if="notif.GoNotification_TypeId === 5 || notif.GoNotification_TypeId === 6 || notif.GoNotification_TypeId === 7 || notif.GoNotification_TypeId === 8">
          <mdb-btn @click.native="doRedirectToShareAnalytics(notif.GoShareId)" size="sm" style="width:fit-content;word-wrap:normal;" class="p-1 p-md-2 m-0 mr-2 deskWidth">
            <div class="showTxtButton">VIEW SHARE</div>
            <div class="showIconButton"><i class="fa fa-eye"></i></div>
          </mdb-btn>
        </div> -->

        <!-- <div v-if="notif.GoNotification_TypeId === 9">
          <mdb-btn @click.native="redirectToRoom(notif.MeetingId)" size="sm" style="width:fit-content;word-wrap:normal;" class="p-1 p-md-2 m-0 mr-2 deskWidth">
            <div class="showTxtButton">ENTER ROOM</div>
            <div class="showIconButton"><i class="fa fa-eye"></i></div>
          </mdb-btn>
        </div> -->

        <!-- <div v-if="notif.GoNotification_TypeId === 10">
          <mdb-btn size="sm" style="width:fit-content;word-wrap:normal;" class="p-1 p-md-2 m-0 mr-2 deskWidth">
            <div class="showTxtButton">SEE CANDIDATE</div>
            <div class="showIconButton"><i class="fa fa-eye"></i></div>
          </mdb-btn>
        </div> -->
        <!-- dismiss notification -->
        <mdb-btn
          class="p-1 p-md-2 d-flex align-items-center justify-content-center btn-outline m-0"
          size="sm"
          style="width:fit-content;height:100%;"
          @click.native="dismissSingleNotification(notif.GoNotification_CentreId)"
        >
          <mdb-icon icon="trash-alt" class="trashIconButton" />
        </mdb-btn>
      </div>
    </div>
    <div
      v-if="notificationsToView.length > 0"
      class="d-flex align-items-center justify-content-between"
      style="width:100%;;"
    >
      <div v-if="mobileView">
        <mdb-btn
          v-if="!allSelected"
          size="sm"
          style="width:fit-content;"
          class="p-1 p-md-2 btn-solid-outline-mob btn m-0"
          @click.native="selectAllNotifications()"
        >
          SELECT ALL
        </mdb-btn>
        <mdb-btn
          v-if="allSelected"
          size="sm"
          style="width:fit-content;"
          class="p-1 p-md-2 btn-solid-outline-mob btn m-0"
          @click.native="deselectAlllNotifications()"
        >
          DESELECT ALL
        </mdb-btn>
        <mdb-btn
          v-if="!buttonClick"
          size="sm"
          style="width:fit-content;"
          class="p-1 p-md-2 btn-solid-outline-mob btn m-0"
          @click.native="dismissSelectedNotifications()"
        >
          DISMISS SELECTED
        </mdb-btn>
        <mdb-btn
          v-else
          size="sm"
          style="width:fit-content;"
          class="p-1 p-md-2 btn-solid-outline-mob btn m-0"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
      <div
        v-else
        style="width:100%;margin-top: 10px !important"
        class="d-flex align-items-center justify-content-between"
      >
        <mdb-btn
          v-if="!allSelected"
          style="width:160px;"
          class="p-1 p-md-2 m-0"
          @click.native="selectAllNotifications()"
        >
          SELECT ALL
        </mdb-btn>
        <mdb-btn
          v-if="allSelected"
          style="width:160px;"
          class="p-1 p-md-2 m-0"
          @click.native="deselectAlllNotifications()"
        >
          DESELECT ALL
        </mdb-btn>
        <mdb-btn
          v-if="!buttonClick"
          style="width:160px;"
          class="p-1 p-md-2 m-0"
          @click.native="dismissSelectedNotifications()"
        >
          DISMISS SELECTED
        </mdb-btn>
        <mdb-btn
          v-else
          style="width:160px;"
          class="p-1 p-md-2 m-0"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
    <div
      v-if="notificationsToView.length == 0 && search == '' && !notificationsLoading"
      style="width:100%;font-size:0.8rem;"
      class="mt-5 text-black"
    >
      <div
        class="primary-colour"
        style="cursor:pointer;text-align:left;margin-top:-50px;"
        :class="{ 'noNots' : mobileView }"
      >
        You have no new notifications to view <i
          v-show="!spinner"
          class="fas fa-sync"
          style="margin-left:5px;"
          @click="getNotifications()"
        />
      </div>
    </div>
    <div v-if="notificationsToView.length == 0 && search != '' && !notificationsLoading" style="width:100%;font-size:0.8rem;text-align:left;">
      No results
    </div>
  </div>
</template>

<script>
import { MEET_URL } from '@/common/config.js'
import { useCandidate, useUserData, useNotification } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import notificationService from '@/api/services/notification.service'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'Notifications',
  components: {
    ExpandableSearchBar
  },
  setup () {
    const candidateStore = useCandidate()
    const { sharingVideoIntro } = storeToRefs(candidateStore)

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const notificationStore = useNotification()
    const {
      notificationFilterType,
      notificationsLoading,
      notifications,
      redirectToVideoCV,
      redirectData,
      redirectToShareAnalytics,
      redirectToOneWay,
      redirectToTwoWay
     } = storeToRefs(notificationStore)

     const { mobileView } = useMobile()

    return {
      candidateStore,
      sharingVideoIntro,
      authData,
      userData,
      notificationFilterType,
      notificationsLoading,
      notifications,
      redirectToVideoCV,
      redirectData,
      redirectToShareAnalytics,
      redirectToOneWay,
      redirectToTwoWay,
      mobileView
    }
  },
  data () {
    return {
      allSelected: false,
      checkedNotificationIds: [],
      notificationsToView: [],
      search: '',
      spinner: false,
      unwatchSearch: null,
      unwatchNotifications: null,
      unwatchNotificationFilterType: null,
      buttonClick: false
    }
  },
  created () {
    this.unwatchSearch = this.$watch('search', function () {
      try {
        if (this.search !== '' && this.notificationFilterType !== 'All') {
          this.notificationFilterType = 'All'
        }
        this.notificationsToView = this.getNot().filter(n => n.NotificationText.toLowerCase().includes(this.search.toLowerCase()))
      } catch {
        this.unwatchAll()
      }
    })
    this.unwatchNotifications = this.$watch('notifications', function () {
      try {
        if (this.notificationFilterType === 'All') { this.notificationsToView = this.notifications; return }
        this.notificationsToView = this.getNot().filter(n => n.NotificationGroup === this.notificationFilterType)
      } catch {
        this.unwatchAll()
      }
    })
    this.unwatchNotificationFilterType = this.$watch('notificationFilterType', function () {
      try {
        if (this.notificationFilterType === 'All') {
          this.notificationsToView = this.getNot()
        } else {
          this.notificationsToView = this.getNot().filter(n => n.NotificationGroup === this.notificationFilterType)
        }
      } catch {
        this.unwatchAll()
      }
      this.deselectAlllNotifications()
    })
  },
  mounted () {
    this.notificationsToView = this.getNot()
  },
  methods: {
    getNot () {
      try {
        return this.notifications
      } catch {
        this.unwatchAll()
        return []
      }
    },
    setNot (value) {
      this.notifications = value
      if (this.sharingVideoIntro.length > 0) {
        this.sharingVideoIntro = []
      }
    },
    async getNotifications () {
      this.notificationsLoading = true
      this.spinner = true
      setTimeout(() => {
        this.spinner = false
      }, 3000)
      try {
        const res = await notificationService.getAllUserNotifications(false)
        this.setNot(res.data)
      } catch (err) {
        //?
      }
      this.notificationsLoading = false
    },
    returnTypeDescriptionFromTypeId (id) {
      return id === 1 ? 'One Way Completion' : id === 11 ? 'Bulk Upload' :id === 12 ? 'Bulk Upload' : id === 13 ? 'Bulk Upload' : id === 15 ? 'Bulk Upload' : id === 2 ? 'Video CV Completion' : (id === 3 || id === 4) ? 'Live Interview Completion' : id === 5 
      ? 'Share Email Opened' : id === 6 
      ? 'Share Video Watched' : id === 7 
      ? 'Share Video Thumbs Up' : id === 8 
      ? 'Share Video Reshared' : id === 9 
      ? 'Candidate In Room First' : 'Self-Registration for Role'
    },
    addOrRemoveFromChecked (notificationId) {
      if (this.checkedNotificationIds.includes(notificationId)) {
        this.checkedNotificationIds = this.checkedNotificationIds.filter(n => n !== notificationId)
      } else {
        this.checkedNotificationIds.push(notificationId)
      }
    },
    checkedArrayContains (notificationId) {
      return this.checkedNotificationIds.includes(notificationId)
    },
    deselectAlllNotifications () {
      this.checkedNotificationIds = []
      this.allSelected = false
    },
    async dismissSingleNotification (notificationId) {
      this.buttonClick = true
      try {
        const formData = new FormData()
        formData.append('notificationIds', JSON.stringify(this.checkedNotificationIds))
        await notificationService.dimissNotifications(formData)
        const newNotifications = this.getNot().filter(n => n.GoNotification_CentreId !== notificationId)
        this.setNot(newNotifications)
        this.buttonClick = false
      } catch (err) {
        this.buttonClick = false
      }
      this.allSelected = false
    },
    async dismissSelectedNotifications () {
      this.buttonClick = true
      try {
        const formData = new FormData()
        formData.append('notificationIds', JSON.stringify(this.checkedNotificationIds))
        await notificationService.dimissNotifications(formData)
        const newNotifications = this.getNot().filter(n => !this.checkedNotificationIds.includes(n.GoNotification_CentreId))
        this.setNot(newNotifications)
        this.buttonClick = false
      } catch (err) {
        this.buttonClick = false
      }
      this.allSelected = false
    },
    getDate (dateString) {
      const date = new Date(dateString).getDate()
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateString)
      const year = new Date(dateString).getDate()
      return `${date} ${month} ${year}`
    },
    doRedirectToVideoCV (CandidateId) {
      this.redirectToVideoCV = true
      this.redirectData = { CandidateId }
      this.$router.push('/candidates').catch(() => { })
    },
    doRedirectToShareAnalytics (GoShareId) {
      this.redirectToShareAnalytics = true
      this.redirectData = GoShareId
    },
    async redirectToInterview (notification) {
      try {
        this.redirectToOneWay = true
        const res = await notificationService.constructRedirectToViewInterviewURL(notification.JobId, notification.CandidateId)
        this.$router.push(`/roles/candidates/${notification.JobId}/${notification.RoleWorkflowStageId}/${res.data.RoleWorkflowStageItemId}?search=${res.data.CandidateEmail}`)
      } catch (err) {
        //?
      }
    },
    async redirectToRoom (MeetingId) {
      try {
        const res = await notificationService.getMeetingCode(MeetingId)
        const url = `${MEET_URL}/?inv=${res.data}`
        if (res.data !== '') {
          window.open(url, '_blank')
        }
      } catch (err) {
        //?
      }
    },
    selectAllNotifications () {
      this.allSelected = true
      this.checkedNotificationIds = this.notificationsToView.map(n => { return n.GoNotification_CentreId })
    },
    unwatchAll () {
      this.unwatchSearch()
      this.unwatchNotifications()
      this.unwatchNotificationFilterType()
    }
  }
}
</script>

<style>
.showTxtButton {
  display:block;
}
.showIconButton {
  display:none;
}
.deskWidth {
  width:110px !important;
}
.bottomNotificationPadding {
  height:calc(100vh - 250px)
}

@media (max-width: 576px) {
  .bottomNotificationPadding {
    height:calc(100vh - 220px)
  }
  .showTxtButton {
    display:none ;
  }
  .showIconButton {
    display:block;
    padding: 1.5px;
    font-size: small;
  }
  .trashIconButton {
    display:block;
    padding: 3px;
    font-size: small;
  }
  .mobileWidth {
    width: 65%;
  }
  .disPadding {
    padding-top:20px;
  }
  .deskWidth {
    width:inherit !important;
  }
}
</style>

<style lang="scss">
.notfMargTop {
  margin-top: -10px !important;
}
.noNots {
  margin-top: -40px !important;
}
</style>
