<template>
  <div
    class="flex flex-col mobTopFilterBar"
    :class="mobileView ? 'mt-3' : 'mt-4 pt-1 '"
  >
    <div
      v-if="!show"
      :key="selectedIndex"
      style="background: #E1E1E1"
      class="w-100 flex flex-row space-x-6 rounded-sm m-auto overflow-x-auto py-2"
    >
      <div
        class="ml-2 mr-auto flex items-center"
      >
        <i
          :style="{color: !previousDayCheck ? '#E1E1E1 !important' : ''}"
          class="fa fa-caret-left  text-2xl dark-grey-text cp"
          @click="previousDay"
        />
      </div>
      <DayCard
        v-for="(day) in daysComputed"
        :key="day.toLocaleString()"
        :day="day"
        :count="getCount(day)"
      />
      <div
        class="mr-2 ml-auto flex items-center"
      >
        <i
          :style="{color: !nextDayCheck ? '#E1E1E1 !important' : ''}"
          class="fa fa-caret-right  text-2xl dark-grey-text cp"
          @click="nextDay()"
        />
      </div>
    </div>
    <div
      v-if="!show"
      style="margin-top: 20px;margin-left: -5px;;"
    >
      <div>
        <mdb-btn
          v-if="!viewMe"
          style="width:220px;float:left;"
          class="p-2 mobileBut"
          @click="viewScheduleType(1)"
        >
          VIEW MY SCHEDULE
        </mdb-btn>
        <mdb-btn
          v-else
          style="width:220px;float:left;"
          class="p-2 mobileBut"
        >
          <i class="fa fa-spin fa-spinner" />
        </mdb-btn>
      </div>
      <div v-if="userData.rle == 'Admin' || userData.iss">
        <mdb-btn
          v-if="!viewAll"
          style="width:220px;float:right;margin-right: 0px;"
          class="p-2 mobileBut"
          @click="viewScheduleType(2)"
        >
          VIEW ALL
        </mdb-btn>
        <mdb-btn
          v-else
          style="width:220px;float:right;margin-right: 0px;"
          class="p-2 mobileBut"
        >
          <i class="fa fa-spin fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { ref, computed, watch } from 'vue'
import { useWorkflow, useEvent, useRoleCandidate, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import DayCard from './dayCard.vue'

export default {
  name: 'ScheduledStatusFilterBarHeader',
  components: { DayCard },
  setup () {

    const workflowStore = useWorkflow()
    const { role, selectedWorkflowStage, selectedWorkflowStageItem } = storeToRefs(workflowStore)
    const eventStore = useEvent()
    const { selectedDate } = storeToRefs(eventStore)
    const { mobileView } = useMobile()

    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const viewMe = ref(false)
    const viewAll = ref(false)

    const dates = ref([])
    const windowWidth = ref(window.innerWidth)

    const workflowName = ref(selectedWorkflowStage.value.RoleWorkflowStageName)
    const isSelfSchedule = ref(selectedWorkflowStage.value.IsSelfSchedule)
    const show = ref(false)

    if (workflowName.value === 'Live Interview' && isSelfSchedule.value) {
      show.value = true
    }
    if (workflowName.value === 'Event') {
      show.value = true
    }
    
    // Loading
    const getCards = () => {
      if (windowWidth.value > 1895) return 15
      else if (windowWidth.value > 1790) return 13
      else if (windowWidth.value > 1680) return 13
      else if (windowWidth.value > 1580) return 11
      else if (windowWidth.value > 1475) return 11
      else if (windowWidth.value > 1370) return 9
      else if (windowWidth.value > 1265) return 9
      else if (windowWidth.value > 1160) return 7
      else if (windowWidth.value > 1055) return 7
      return 3
    }

    const selectedIndex = ref(0)
    var startDate = ref(new Date(selectedWorkflowStage.value.StartDate))
    var endDate = ref(new Date(selectedWorkflowStage.value.EndDate))

    var date = new Date(selectedWorkflowStage.value.StartDate)
    date = date.setDate(date.getDate() - 30); 
    startDate = ref(new Date(date))

    if (role.value.IsEvergreen) {
      date = new Date()
      date = date.setDate(date.getDate() + 365);
      endDate = ref(new Date(date))
    }
    
    const getDays = () => {
      const dateRange = moment(endDate.value).diff(startDate.value, 'days')
      for (let index = 0; index < dateRange + 1 ; index++) {
        let tomorrow = new Date(Date.UTC(startDate.value.getUTCFullYear(), startDate.value.getUTCMonth(), startDate.value.getUTCDate() + index))
        dates.value.push(tomorrow)
      }
      // Pre-select today
      // selectedIndex.value = dates.value.findIndex(x => moment(selectedDate.value).format("DD-MM-YYYY") === moment(x).format("DD-MM-YYYY"))
    }
    getDays()

    // const daysComputed = ref(dates?.value?.slice(0))
    const daysComputed = ref([])

    // sets the day counts
    const { getDateFilterCount, viewSchedule } = useRoleCandidate()
    const roleCandidateStore = useRoleCandidate()
    const { dateFilterCounts } = storeToRefs(roleCandidateStore)
    const getDateBookingCounts = async () => {
      daysComputed.value.forEach(async day => {
        let momentDay = moment(day).format('YYYY-MM-DD');
        if (dateFilterCounts.value.filter(v => v.day == momentDay).length == 0) {
          getDateFilterCount(day, selectedWorkflowStageItem.value.RoleWorkflowStageItemId)
        }
      })
    }

    const getCount = day => {
      let momentDay = moment(day).format('YYYY-MM-DD')
      const obj = dateFilterCounts.value.filter(v => v.day == momentDay)[0]
      if (obj == undefined) return -1
      return obj.count
    }

    watch(daysComputed, async () => {
      await getDateBookingCounts()
    }, { immediate: true, deep: true })

    const currentStageItemId = computed(() => selectedWorkflowStageItem.value.RoleWorkflowStageItemId)
    watch(currentStageItemId, () => dateFilterCounts.value = [])

    watch(selectedIndex, () => {
      if (selectedIndex.value === 0) {
        daysComputed.value = dates?.value?.slice(0, getCards())
      } else if (selectedIndex.value == dates.value.length - 1) {
        let lowerIndex = dates.value.length - getCards()
        lowerIndex = lowerIndex < 0 ? 0 : lowerIndex
        daysComputed.value = dates?.value?.slice(lowerIndex, dates.value.length)
      } else {
        // this code is basically finding the correct chunk of days to pick. it accounts for screen size using (getCards()).
        // it selects the correct number of days either side of the centrally selected day
        // but also considers that you may be selecting a day at the end or start of the stage dates and so then adds extra days to either the start or end of the day array
        let lowerIndex = selectedIndex.value - ((getCards() - 1) / 2)
        const lowerDifferenceToZero = Math.abs(0 + lowerIndex)
        lowerIndex = lowerIndex < 0 ? 0 : lowerIndex
        let higherIndex = selectedIndex.value + ((getCards() - 1) / 2)
        let higherDifferenceToEnd = Math.abs(dates.value.length - higherIndex)

        higherIndex = higherIndex > dates.value.length ? dates.value.length : higherIndex
        if (lowerIndex >= higherDifferenceToEnd) {
          lowerIndex -= (higherDifferenceToEnd + 1)
          if (selectedIndex.value - lowerIndex > ((getCards() - 1) / 2) && higherIndex != dates.value.length) {
            lowerIndex += higherDifferenceToEnd + 1
          }
        }
        if (higherIndex - lowerDifferenceToZero == (getCards() - 1) || lowerDifferenceToZero < (getCards() - 1)) {
          higherIndex += (lowerDifferenceToZero + 1)
          if (higherIndex - selectedIndex.value > ((getCards() - 1) / 2) && lowerIndex > 0) {
            higherIndex -= (lowerDifferenceToZero)
          }
        }
        lowerIndex = lowerIndex < 0 ? 0 : lowerIndex
        higherIndex = higherIndex > dates.value.length ? dates.value.length : higherIndex
        const arr = dates?.value?.slice(lowerIndex, higherIndex)
        daysComputed.value = arr
      }
    })

    // Navigation Logic
    const previousDayCheck = computed(() => selectedIndex.value !== 0)
    const nextDayCheck = computed(() => selectedIndex.value != dates.value.length - 1)

    const nextDay = () => {
      if (!nextDayCheck.value) return
      selectedDate.value = new Date(Date.UTC(selectedDate.value.getUTCFullYear(), selectedDate.value.getUTCMonth(), selectedDate.value.getUTCDate() + 1))
    }
    const previousDay = () => {
      if (!previousDayCheck.value) return
      selectedDate.value = new Date(Date.UTC(selectedDate.value.getUTCFullYear(), selectedDate.value.getUTCMonth(), selectedDate.value.getUTCDate() - 1))
    }

    const viewScheduleType = async (type) => {
      if (type == 1) {
        viewMe.value = true
      }
      if (type == 2) {
        viewAll.value = true
      }
      await viewSchedule(selectedWorkflowStageItem.value.RoleWorkflowStageItemId, type)
      viewMe.value = false
      viewAll.value = false
    }

    watch(selectedDate, async () => {
      const selectedDateIndex = dates.value.findIndex(x => moment(x).format("DD-MM-YYYY") === moment(selectedDate.value).format("DD-MM-YYYY"))
      selectedIndex.value = selectedDateIndex
    }, { immediate: true })

    return {
      dates,
      daysComputed,
      mobileView,
      windowWidth,
      nextDay,
      previousDay,
      selectedIndex,
      nextDayCheck,
      previousDayCheck,
      getCards,
      selectedDate,
      dateFilterCounts,
      getCount,
      startDate,
      endDate,
      viewScheduleType,
      viewMe,
      viewAll,
      userData,
      workflowName,
      isSelfSchedule,
      show
    }
  },
  created () {
    if (this.show) {
      this.viewScheduleType(2)
    }
  }
}
</script>
<style scoped>
@media (max-width: 576px) {
  .mobileBut {
    width: calc(100% - 5px) !important;
  }
  .mobTopFilterBar {
    margin-top: 40px !important;
  }
}
</style>
