<template>
  <div class="min-h-full pt-2 pt-md-4 px-3 px-md-5 w-1/1 text-black rounded" style="height:calc(100vh - 190px);overflow-y:auto;margin-bottom:65px;overflow-x:hidden">
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="reportsLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>
      <div
        v-if="reportsLoaded"
        class="text-left"
        style="margin-top:20px;"
      >
        <div>
          <p class="font-weight-bold" style="margin-bottom:20px;">
            Usage Reports
          </p>
          <p class="grey-text" style="font-size:12px;">
            Click the button below to download an overall useage report of existing roles.
          </p>
          <mdb-btn
            v-show="!spinningFlag"
            class="fWidth"
            size="sm"
            style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
            @click.native="getReportSummaryDownload()"
          >
            DOWNLOAD USAGE REPORT <i class="fa fa-download" />
          </mdb-btn>
          <mdb-btn
            v-show="spinningFlag"
            class="fWidth"
            color="primary"
            size="sm"
            style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
        <div
          v-if="userData.crp === 1"
          class="text-left"
          style="margin-top:0px;"
        >
          <div>
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Custom Scoring Data
            </p>
            <p class="grey-text" style="font-size:12px;">
              Click the button below to download scoring data.
            </p>
            <mdb-btn
              v-show="!scoringSpinner"
              class="fWidth"
              size="sm"
              style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
              @click.native="getScoringReport()"
            >
              DOWNLOAD SCORING <i class="fa fa-download" />
            </mdb-btn>
            <mdb-btn
              v-show="scoringSpinner"
              class="fWidth"
              color="primary"
              size="sm"
              style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </div>
        </div>

        <div v-if="reportsLoaded">
          <div v-if="rolesSummary != null" class="text-left">
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Individual Roles
            </p>
            <p class="grey-text webDisplay" style="font-size:12px;">
              Choose any of the options in the dropdown below to download details from each of the roles (one-way)
            </p>
            <p class="grey-text mobDisplay" style="font-size:12px;">
              The following roles are available to download individually<br><br>
            </p>
            <div class="webDisplay">
              <b-dropdown
                id="dropdown-download-roles"
                size="sm"
                text="Download Individual Roles"
                variant="transparent"
                toggle-class=""
                style="margin-left:0px;display:block;"
              >
                <b-dropdown-item-button class="deskW" @click.native="getRoleReportData(-1)">
                  <span style="font-size: 0.8rem;">Download All (active 1W only) <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                </b-dropdown-item-button>
                <b-dropdown-item-button @click.native="getRoleReportData(-3)">
                  <span style="font-size: 0.8rem;">Download All (include inactive 1W only) <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                </b-dropdown-item-button>
                <b-dropdown-divider />
                <b-dropdown-item-button
                  v-for="(role) in rolesSummary"
                  :key="role.RoleId"
                  @click.native="getRoleReportData(role.RoleId)"
                >
                  <span v-if="role.IsActive" style="font-size: 0.8rem;">{{ role.JobTitle }} <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                  <span v-else style="font-size: 0.8rem;color:#c0c0c0;">{{ role.JobTitle }} <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
            <div class="mobDisplay">
              <mdb-btn
                v-for="(role) in rolesSummary"
                :key="role.RoleId"
                class="fWidth"
                size="sm"
                style="text-align:left;width:333px;margin-left:0px;padding: 0.36rem 1.1rem;"
                @click="getRoleReportData(role.RoleId)"
              >
                {{ role.JobTitle }} <i class="fa fa-download secondary-colour" style="margin-right:-10px;margin-left:5px;float:right;margin-top: 3px;" />
              </mdb-btn>
            </div>
          </div>
          <div v-if="reportsLoaded && rolesSummary === null" class="text-left">
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Individual Roles
            </p>
            <p class="grey-text" style="font-size:12px;">
              There doesn't seem to be any created, modified or followed roles for your account.
            </p>
          </div>
        </div>

        <!--removed-->
        <div v-if="reportsLoaded" style="display: none;">
          <div
            v-if="rolesSummary != null && reportData[0].ScoringTemplate === true"
            class="text-left webDisplayMargin"
          >
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Scoring Reports
            </p>
            <p class="grey-text webDisplay" style="font-size:12px;">
              Choose any of the options in the dropdown below to download scoring data per candidate per one-way role (if scoring enabled)
            </p>
            <p class="grey-text mobDisplay" style="font-size:12px;">
              The following roles are available to download scoring data individually<br><br>
            </p>
            <div class="webDisplay deskW">
              <b-dropdown
                id="dropdown-download-roles"
                size="sm"
                text="Download Scoring Report"
                variant="transparent"
                toggle-class=""
                style="margin-left:0px;display:block;"
              >
                <b-dropdown-item-button
                  v-for="(role) in rolesSummary"
                  :key="role.RoleId"
                  class="deskW"
                  @click.native="getRoleScoringReport(role.RoleId)"
                >
                  <span v-if="role.IsActive" style="font-size: 0.8rem;">{{ role.JobTitle }} <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                  <span v-else style="font-size: 0.8rem;color:#c0c0c0;">{{ role.JobTitle }} <i class="fa fa-download" style="color:#555;margin-left:5px;float:right;margin-top: 5px;" /></span>
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
            <div class="mobDisplay">
              <mdb-btn
                v-for="(role) in rolesSummary"
                :key="role.RoleId"
                class="fWidth"
                size="sm"
                style="text-align:left;width:333px;margin-left:0px;padding: 0.36rem 1.1rem;"
                @click="getRoleReportData(role.RoleId)"
              >
                {{ role.JobTitle }} <i class="fa fa-download secondary-colour" style="margin-right:-10px;margin-left:5px;float:right;margin-top: 3px;" />
              </mdb-btn>
            </div>
          </div>
          <div v-if="reportsLoaded && rolesSummary === null" class="text-left">
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Individual Roles
            </p>
            <p class="grey-text" style="font-size:12px;">
              There doesn't seem to be any created, modified or followed roles for your account.
            </p>
          </div>
        </div>
      
        <!--removed-->
        <div
          v-if="reportsLoaded && userData.nsv && (userData.iss || userData.rle === 'Admin' || userData.rle === 'Account Group Administrator')"
          style="margin-top:70px;display:none;"
          class="mobileTopHeight"
        >
          <div v-if="rolesSummary != null" class="text-left">
            <p class="font-weight-bold" style="margin-bottom:20px;">
              Survey Reports
            </p>
            <p class="grey-text webDisplay" style="font-size:12px;">
              Choose any of the options in the dropdown below to download survey details from each of the roles
            </p>
            <p class="grey-text mobDisplay" style="font-size:12px;">
              The following roles are available to download survey data<br><br>
            </p>

            <div class="webDisplay">
              <mdb-btn
                v-if="surveySpinner"
                class="fWidth"
                color="primary"
                size="sm"
                style="width:333px;margin-top:20px;margin-bottom:20px;margin-left:0px;padding: 0.36rem 1.1rem;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
              <b-dropdown
                v-else
                id="dropdown-download-roles"
                size="sm"
                text="Download Survey Data"
                variant="transparent"
                toggle-class=""
                style="margin-left:0px;display:block;"
              >
                <b-dropdown-item-button class="deskW" @click.native="downloadSurveyData(-1)">
                  <span style="font-size: 0.8rem;">Download All (active 1W only)</span>
                </b-dropdown-item-button>
                <b-dropdown-item-button @click.native="downloadSurveyData(-3)">
                  <span style="font-size: 0.8rem;">Download All (include inactive 1W only)</span>
                </b-dropdown-item-button>
                <b-dropdown-item-button
                  v-for="(role) in rolesSummary"
                  v-show="role.IsActive"
                  :key="role.RoleId"
                  @click.native="downloadSurveyData(role.RoleId)"
                >
                  <span style="font-size: 0.8rem;">{{ role.JobTitle }}</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
            <div class="mobDisplay">
              <mdb-btn
                v-for="(role) in rolesSummary"
                v-show="role.IsActive"
                :key="role.RoleId"
                class="fWidth"
                size="sm"
                style="text-align:left;width:333px;margin-left:0px;padding: 0.36rem 1.1rem;"
                @click="downloadSurveyData(role.RoleId)"
              >
                {{ role.JobTitle }} <i class="fa fa-download" style="float:right;" />
              </mdb-btn>
            </div>
          </div>
          <div v-if="reportsLoaded && rolesSummary === null" class="text-left">
            <p class="font-weight-bold" style="margin-bottom:20px;margin-top:-40px;">
              Survey Reports
            </p>
            <p class="grey-text" style="font-size:12px;">
              There doesn't seem to be any created, modified or followed roles for your account.
            </p>
          </div>
        </div>

        <mdb-modal
          id="confirmReportSentModal"
          :show="confirmReportSentModal"
          @close="confirmReportSentModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>YOUR REPORT</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body>
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              {{ modalMsg }}
            </p>
          </mdb-modal-body>
          <mdb-modal-footer style="display:block;">
            <mdb-btn
              type="reset"
              class="float-left btn-outline"
              size="sm"
              style="width:100px;"
              @click="confirmReportSentModal = false"
            >
              CLOSE
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import reportService from '@/api/services/report.service'

import { mdbBtn } from 'mdbvue'

export default {
  name: 'RoleTab',
  components: {
    mdbBtn
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      authData,
      userData,
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      confirmReportSentModal: false,
      reportData: null,
      rolesSummary: null,
      summaryDownloadUrl: '',
      reportsLoaded: false,
      spinningFlag: false,
      scoringSpinner: false,
      modalMsg: '',
      downloadSurveyDataUrl: false,
      surveySpinner: false
    }
  },
  mounted () {
    this.reportsSummary()
  },
  methods: {
    async reportsSummary () {
      try {
        const res = await reportService.reportsSummary()
        this.reportData = [res.data]
        this.rolesSummary = res.data.RolesSummary.Roles
        this.reportsLoaded = true
        this.$parent.reportsLoaded = true
      } catch (err) {
        this.reportsLoaded = true
        this.$parent.reportsLoaded = true
      }
    },
    async getRoleReportData (roleId) {
      try {
        this.modalMsg = 'If data exists for the selected role(s), you will be emailed a report shortly.'
        this.confirmReportSentModal = true
        await reportService.getRoleReportData(roleId, false)
      } catch (err) {
        //?
      }
    },
    async getReportSummaryDownload () {
      this.spinningFlag = true
      try {
        const res = await reportService.getReportSummaryDownload(this.userData.aid, true)
        this.summaryDownloadUrl = res.data
        if (res.data) {
          window.location.assign(res.data)
        }
        setTimeout(() => {
          this.spinningFlag = false
        }, 1500)
      } catch (err) {
        this.spinningFlag = false
        console.log(Object.assign({}, err))
      }
    },
    async downloadSurveyData (jobId) {
      this.surveySpinner = true
      try {
        const res = await reportService.downloadSurveyData(jobId)
        this.downloadSurveyDataUrl = res.data
        if (res.data) {
          window.location.assign(res.data)
        }
      } catch (err) {
        this.showGenericErrorModal({ showing: true, errorTitle: 'No Data', errorMessage: err.response.data.Message })
      }
      setTimeout(() => {
        this.surveySpinner = false
      }, 1500)
    },
    async getRoleScoringReport (roleId) {
      this.scoringSpinner = true
      try {
        this.modalMsg = 'If data exists for the selected role(s), you will be emailed a scoring report shortly.'
        this.confirmReportSentModal = true
        await reportService.getRoleScoringReport(roleId)
      } catch (err) {
        //?
      }
      setTimeout(() => {
        this.scoringSpinner = false
      }, 1500)
    },
    async getScoringReport () {
      this.scoringSpinner = true
      try {
        this.modalMsg = 'If data exists for the selected role(s), you will be emailed a scoring report shortly.'
        this.confirmReportSentModal = true
        await reportService.getScoringReport()
      } catch (err) {
        //?
      }
      setTimeout(() => {
        this.scoringSpinner = false
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .webDisplayMargin {
    margin-top: 75px;
  }
  .deskW {
    width:315px;
  }
  ::v-deep {
    .dropdown-menu {
      margin-top: 10px;
      position: absolute !important;
    }
    div#dropdown-download-roles {
      .dropdown-toggle {
        float: left;
        width: 333px;
        margin-top: 20px;
        margin-bottom: -35px;
        margin-left: 0px;
        padding: 0.36rem 1.1rem;
        text-transform: uppercase!important;
      }
    }
  }
  @media (max-width: 576px) {
    .mobileTopHeight{
      margin-top:20px !important;
    }
  }
  @media (max-width: 639px) {
    .deskW {
      width:inherit;
    }
    .fWidth {
      width:100% !important;
    }
    ::v-deep {
      dropdown-menu {
        left: 40px !important;
      }
      div#dropdown-download-roles {
        .dropdown-toggle {
          float: left;
          width: 100% !important;
          margin-top: 20px;
          margin-bottom: -35px;
          margin-left: 0px;
          padding: 0.36rem 1.1rem;
          text-transform: uppercase!important;
        }
      }
    }
  }

</style>
