<template>
  <div class="text-black rounded" style="height:fit-contentoverflow-y:auto;overflow-x:hidden;margin-top: 60px;">
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="settingsLoaded === false" :class="{ 'marginTopSections': mobileView === false }">
        <h3 class="primary-colour text-left font-weight-bold" :class="{ 'mobileTopSections': mobileView }">
          Loading
          <i
            style="margin-left: 5px !important;"
            class="fa fa-spin fa-spinner ml-3"
            :class="{ 'mobileTopSectionSpinner': mobileView }"
          />
        </h3>
      </div>
      <div v-show="settingsLoaded === true" class="grey-text text-left mb-4 mx-0">
        <p
          style="margin-top:20px;font-size:14px;"
          :class="{ 'extraMarginTop': mobileView }"
          class="grey-text"
        >
          These are your default settings for notifications. You can toggle on or off the notifications you wish to recieve (in app or via email or both).
        </p>
      </div>
      <b-row
        no-gutters
        class="text-black mt-4 font-small"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto font-italic"
          style="max-width:200px;"
        >
          COMPLETIONS
        </b-col>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="bell" style="transform:scale(1.4)" />
        </div>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="envelope" style="transform:scale(1.4)" />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-2 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          On Completion of an Interview for a Role
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompRoleInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompRoleEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-1 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          On Completion of a Video CV request
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompVideoCvInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompVideoCvEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-1 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          On Completion of a Live Interview
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompLiveInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="CompLiveEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <!-- SAHRING -->
      <b-row
        no-gutters
        class="text-black mt-4 font-small"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto font-italic"
          style="max-width:200px;"
        >
          SHARING
        </b-col>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="bell" style="transform:scale(1.4)" />
        </div>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="envelope" style="transform:scale(1.4)" />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-2 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a Share email is opened by the receipient
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareEmailOpenInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareEmailOpenEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-1 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a Shared Video is watched
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoWatchedInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoWatchedEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-1 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a Shared Video is given a thumbs up
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoThumbsUpInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoThumbsUpEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-1 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a Shared Video is re-shared to someone else
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoResharedInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="ShareVideoResharedEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <!-- Live Interviews -->
      <b-row
        no-gutters
        class="text-black mt-4 font-small"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto font-italic"
          style="max-width:200px;"
        >
          LIVE INTERVIEWS
        </b-col>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="bell" style="transform:scale(1.4)" />
        </div>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="envelope" style="transform:scale(1.4)" />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-2 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a candidate arrives in a room before you
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="LiveCandidateEntersPriorInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="LiveCandidateEntersPriorEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <!-- Self-Registration -->
      <b-row
        no-gutters
        class="text-black mt-4 font-small"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto font-italic"
          style="max-width:200px;"
        >
          SELF-REGISTRATION
        </b-col>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="bell" style="transform:scale(1.4)" />
        </div>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="envelope" style="transform:scale(1.4)" />
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-2 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          When a candidate self-registers for a role
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="SelfRegisterForRoleInApp"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          <mdb-switch
            v-model="SelfRegisterForRoleEmail"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
      </b-row>
      <!-- Bulk -->
      <b-row
        no-gutters
        class="text-black mt-4 font-small"
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto font-italic"
          style="max-width:200px;"
        >
          BULK UPLOADS
        </b-col>
        <div style="width:57px;" class="d-flex justify-content-center">
          <mdb-icon icon="bell" style="transform:scale(1.4)" />
        </div>
        <div style="width:57px;" class="d-flex justify-content-center">
          &nbsp;
        </div>
      </b-row>
      <b-row
        no-gutters
        class="text-black mt-2 font-small "
        align-v="center"
      >
        <b-col
          cols="auto"
          class="mr-auto"
          style="max-width:55%;font-size:0.8rem;"
        >
          Notification of failed bulk upload data
        </b-col>
        <div style="width:57px;">
          <mdb-switch
            v-model="BulkUploadNotification"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0"
          />
        </div>
        <div style="width:57px;">
          &nbsp;        
        </div>
      </b-row>
      <!-- Auto disiss -->
      <div
        cols="auto"
        class="text-black mt-4 font-small font-italic"
        style="max-width:300px;margin-top:20px;font-size:14px;"
      >
        AUTO DISMISS NOTIFICATIONS
      </div>

      <b-row
        no-gutters
        class="d-flex"
        align-v="center"
        @click="autoDismissCheckbox = !autoDismissCheckbox"
      >
        <div class="mr-auto d-flex align-items-center">
          <div class="d-inline mt-1">
            <mdb-input
              type="checkbox"
              off-label=""
              on-label=""
              :value="autoDismissCheckbox"
              class="p-0"
              @ontouch="autoDismissCheckbox = !autoDismissCheckbox"
              @click="autoDismissCheckbox = !autoDismissCheckbox"
            />
          </div>
          <div class="mr-auto d-inline text-black" style="font-size:0.8rem;">
            Automatically dismiss notifications after
          </div>
        </div>
      </b-row>
      <div class="d-flex align-items-center justify-content-between" style="transform:translateY(-20px);">
        <mdb-select
          v-model="autoDismissOptions"
          :disabled="!autoDismissCheckbox"
          auto-complete="hou"
          style="width:280px;"
          size="sm"
          class="p-0 m-0"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between" style="transform:translateY(-20px);float: right;margin-top: 0px;margin-right: -5px;padding-bottom:50px;">
        <mdb-btn
          v-if="settingsSaved && settingsLoaded"
          class="float-right"
          size="sm"
          style="width:100px;margin-top:30px;"
          @click.native="saveSettings()"
        >
          SAVE
        </mdb-btn>
        <mdb-btn
          v-else
          class="float-right"
          size="sm"
          style="width:100px;margin-top:30px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import notificationService from '@/api/services/notification.service'

export default {
  name: 'NotificationSettings',
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      authData,
      mobileView
    }
  },
  data () {
    return {
      autoDismissCheckbox: true,
      autoDismissOptions: [
        { value: 'TwentyFourHours', text: '24 Hours' },
        { value: 'FortyEightHours', text: '48 Hours' },
        { value: 'SeventyTwoHours', text: '72 Hours' },
        { value: 'OneWeek', text: 'One Week', selected: true },
        { value: 'TwoWeeks', text: 'Two Weeks' }
      ],
      CompLiveEmail: true,
      CompLiveInApp: true,
      CompRoleEmail: true,
      CompRoleInApp: true,
      CompVideoCvEmail: true,
      CompVideoCvInApp: true,
      LiveCandidateEntersPriorEmail: true,
      LiveCandidateEntersPriorInApp: true,
      ShareEmailOpenEmail: true,
      ShareEmailOpenInApp: true,
      ShareVideoResharedEmail: true,
      ShareVideoResharedInApp: true,
      ShareVideoThumbsUpEmail: true,
      ShareVideoThumbsUpInApp: true,
      ShareVideoWatchedEmail: true,
      ShareVideoWatchedInApp: true,
      SelfRegisterForRoleInApp: true,
      SelfRegisterForRoleEmail: true,
      BulkUploadNotification: true,
      settingsLoaded: true,
      settingsSaved: true
    }
  },
  computed: {
    AutoDismissAfter () {
      if (this.autoDismissCheckbox === false) { return 'Never' }
      return this.autoDismissOptions.filter(o => o.selected === true)[0].value
    }
  },
  created () {
    this.loadNotificationSettings()
  },
  methods: {
    async loadNotificationSettings () {
      this.settingsLoaded = false
      try {
        const res = await notificationService.getNotificationSettings()
        if (res.data.AutoDismissAfter === 'Never') {
          this.autoDismissCheckbox = false
        } else {
          this.autoDismissCheckbox = true
          this.autoDismissOptions = this.autoDismissOptions.map(o => o.value === res.data.AutoDismissAfter ? { ...o, selected: true } : { ...o, selected: false })
        }
        this.CompLiveEmail = res.data.CompLiveEmail
        this.CompLiveInApp = res.data.CompLiveInApp
        this.CompRoleEmail = res.data.CompRoleEmail
        this.CompRoleInApp = res.data.CompRoleInApp
        this.CompVideoCvEmail = res.data.CompVideoCvEmail
        this.CompVideoCvInApp = res.data.CompVideoCvInApp
        this.LiveCandidateEntersPriorEmail = res.data.LiveCandidateEntersPriorEmail
        this.LiveCandidateEntersPriorInApp = res.data.LiveCandidateEntersPriorInApp
        this.ShareEmailOpenEmail = res.data.ShareEmailOpenEmail
        this.ShareEmailOpenInApp = res.data.ShareEmailOpenInApp
        this.ShareVideoResharedEmail = res.data.ShareVideoResharedEmail
        this.ShareVideoResharedInApp = res.data.ShareVideoResharedInApp
        this.ShareVideoThumbsUpEmail = res.data.ShareVideoThumbsUpEmail
        this.ShareVideoThumbsUpInApp = res.data.ShareVideoThumbsUpInApp
        this.ShareVideoWatchedEmail = res.data.ShareVideoWatchedEmail
        this.ShareVideoWatchedInApp = res.data.ShareVideoWatchedInApp
        this.SelfRegisterForRoleInApp = res.data.SelfRegisterForRoleInApp
        this.SelfRegisterForRoleEmail = res.data.SelfRegisterForRoleEmail
        this.BulkUploadNotification = res.data.BulkUploadNotification
      } catch (err) {
        //?
      }
      this.settingsLoaded = true
    },
    async saveSettings () {
      this.settingsSaved = false
      const formData = new FormData()
      formData.append('AutoDismissAfter', this.AutoDismissAfter)
      formData.append('CompLiveEmail', this.CompLiveEmail)
      formData.append('CompLiveInApp', this.CompLiveInApp)
      formData.append('CompRoleEmail', this.CompRoleEmail)
      formData.append('CompRoleInApp', this.CompRoleInApp)
      formData.append('CompVideoCvEmail', this.CompVideoCvEmail)
      formData.append('CompVideoCvInApp', this.CompVideoCvInApp)
      formData.append('LiveCandidateEntersPriorEmail', this.LiveCandidateEntersPriorEmail)
      formData.append('LiveCandidateEntersPriorInApp', this.LiveCandidateEntersPriorInApp)
      formData.append('ShareEmailOpenEmail', this.ShareEmailOpenEmail)
      formData.append('ShareEmailOpenInApp', this.ShareEmailOpenInApp)
      formData.append('ShareVideoResharedEmail', this.ShareVideoResharedEmail)
      formData.append('ShareVideoResharedInApp', this.ShareVideoResharedInApp)
      formData.append('ShareVideoThumbsUpEmail', this.ShareVideoThumbsUpEmail)
      formData.append('ShareVideoThumbsUpInApp', this.ShareVideoThumbsUpInApp)
      formData.append('ShareVideoWatchedEmail', this.ShareVideoWatchedEmail)
      formData.append('ShareVideoWatchedInApp', this.ShareVideoWatchedInApp)
      formData.append('SelfRegisterForRoleInApp', this.SelfRegisterForRoleInApp)
      formData.append('SelfRegisterForRoleEmail', this.SelfRegisterForRoleEmail)
      formData.append('BulkUploadNotification', this.BulkUploadNotification)
      try {
        await notificationService.createEditNotificationSettings(formData)
      } catch {
        //?
      }
      this.settingsSaved = true
    }
  }
}
</script>

<style lang="scss" scoped>
.extraMarginTop {
  margin-top:35px !important;
}
</style>
