<template>
  <div class="col-sm mx-0 mx-lg-3 vLib py-3">
    <div class="font-weight-bold text-left my-3 d-flex align-items-center justify-content-between" style="height:50px">
      <div>
        <span v-if="mobileView">
          <span v-if="meetNowFilterType === 'Available Rooms'">Available</span>
          <span v-if="meetNowFilterType === 'My Recordings'">Recordings</span>
          <span v-if="meetNowFilterType === 'Public Recordings'">Public</span>
          <span v-if="meetNowFilterType === 'Archived Recordings'">Archived</span>
        </span>
        <span v-else>
          {{ meetNowFilterType }}
        </span>
        <i
          v-show="(loadingRecordings || loadingRooms) && mobileView === false"
          style="margin-left: 5px !important;"
          class="fa fa-spin fa-spinner ml-3"
        />
      </div>

      <ExpandableSearchBar
        :width="300"
        :max-widthpercentage="40"
        @search="search = $event"
      />

      <div class="ml-3 d-flex">
        <div
          style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
          :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-grid"
            style="height:25px;width:25px;"
            @click="listView = false"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
          :style="{'background' : listView === false ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-grid"
            style="height:17px;width:17px;"
            @click="listView = false"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:40px;width:40px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-none d-md-flex"
          :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-list-ul"
            style="height:25px;width:25px;"
            @click="listView = true"
          />
        </div>
        <div
          style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
          class="listViewSelected align-items-center justify-content-center mr-1 d-flex d-md-none"
          :style="{'background' : listView === true ? `${userData.lbl.PrimaryColour}66` : 'none'}"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-list-ul"
            style="height:17px;width:17px;"
            @click="listView = true"
          />
        </div>
      </div>
    </div>
    <div v-if="meetNowFilterType == 'Available Rooms'" class="meetNowContentHeight">
      <AvailableRooms
        :meet-now-filter-type="meetNowFilterType"
        :create-room-modal-showing="createRoomModalShowing"
        :list-view="listView"
        :search="search"
        @createRoomModalShowing="createRoomModalShowing = false"
      />
    </div>
    <div v-if="meetNowFilterType == 'My Recordings' || meetNowFilterType == 'Public Recordings' || meetNowFilterType == 'Archived Recordings'" class="meetNowContentHeight">
      <Recordings
        :meet-now-from-redirect="meetNowFromRedirect"
        :create-room-modal-showing="createRoomModalShowing"
        :list-view="listView"
        :search="search"
        @createRoomModalShowing="createRoomModalShowing = false"
      />
    </div>

    <div
      v-show="mobileView"
      class="fixed bottom-0"
      style="background:white;width:100%;border-top: 1px solid #a1a1a1;z-index:1049;margin-left:-15px;height:55px;"
    >
      <div class="d-flex align-items-center justify-content-between" style="height:100%;">
        <div class="d-flex align-items-center ml-3">
          <div
            v-if="meetNowFilterType !== 'Available Rooms'"
            class="underline mr-2"
            style="font-size:1rem;cursor:pointer;"
            @click="meetNowFilterType = 'Available Rooms'"
          >
            <i class="fa fa-tv" title="Available Rooms" />
          </div>
          <div
            v-if="meetNowFilterType !== 'My Recordings'"
            class="underline mr-2"
            style="font-size:1rem;cursor:pointer;"
            @click="meetNowFilterType = 'My Recordings'"
          >
            <i class="fa fa-video" title="My Recordings" />
          </div>
          <div
            v-if="meetNowFilterType !== 'Public Recordings'"
            class="underline mr-2"
            style="font-size:1rem;cursor:pointer;"
            @click="meetNowFilterType = 'Public Recordings'"
          >
            <i class="fa fa-bullhorn" title="Public Recordings" />
          </div>
          <div
            v-if="meetNowFilterType !== 'Archived Recordings'"
            class="underline mr-2"
            style="font-size:1rem;cursor:pointer;"
            @click="meetNowFilterType = 'Archived Recordings'"
          >
            <i class="fa fa-archive" title="Archived Recordings" />
          </div>
        </div>
        <mdb-btn
          v-if="userData.iss"
          class="btn btn-solid-outline-mob mr-3"
          size="sm"
          style="width:110px;"
          @click="createRoom()"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>

    <!--left column bottom menu-->
    <div
      v-if="mobileView === false && (leftColumnActive === false || leftColumnActive === undefined)"
      class="fixed bottom-0 botVidBut moveCreateRoom"
      style="margin-bottom: -3px;background:#d1d1d1;padding:11px;border-top: 1px solid #a1a1a1;"
    >
      <div class="row" style="margin-left:25px;">
        <div class="col-sm-12 text-center">
          <mdb-btn
            size="sm"
            class="btn btn-solid-outline-web"
            style="width:110px;padding: 0.5rem 1.1rem;"
            @click="createRoom()"
          >
            <i class="fa fa-plus-circle" /> ADD
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeetNow, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'

import Recordings from './Pages/Recordings/recordings.vue'
import AvailableRooms from './Pages/Rooms/availableRooms.vue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

export default {
  name: 'MeetNow',
  components: {
    AvailableRooms,
    Recordings,
    ExpandableSearchBar
  },
  setup () {
    const meetNowStore = useMeetNow()
    const { meetNowFilterType } = storeToRefs(meetNowStore)

    const userStore = useUserData()
    const { authData, userData, leftColumnActive } = storeToRefs(userStore)
    const { mobileView } = useMobile()

    return {
      meetNowFilterType,
      authData,
      userData,
      leftColumnActive,
      mobileView
    }
  },
  data () {
    return {
      allSelected: false,
      createRoomModalShowing: false,
      listView: false,
      search: '',
      loadingRecordings: false,
      loadingRooms: false,
      meetNowFromRedirect: []
    }
  },
  computed: {
    filterOptions () {
      const thing = ['Available Rooms', 'My Recordings', 'Public Recordings', 'Archived Recordings']
      return thing.filter(th => th !== this.meetNowFilterType)
    }
  },
  created () {
    this.meetNowFilterType = 'Available Rooms'
    if (this.userData.rle == 'Inviter') {
      window.location.assign('/roles')
    }
  },
  methods: {
    createRoom () {
      this.createRoomModalShowing = true
    }
  }
}
</script>

<style>
.listViewSelected {
  transition: ease 0.5s;
}
</style>

<style lang="css" scoped>
.meetNowContentHeight {
  height:calc(100vh - 230px)
}
@media (min-width: 768px) {
  .meetNowContentHeight {
    height:calc(100vh - 200px)
  }
}
</style>

<style lang="scss">
@media (max-width: 992px) {
  .moveCreateRoom {
    transform: translateX(15px)
  }
}
</style>
