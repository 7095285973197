<template>
  <div class="text-left" style="min-width:100%;height:fit-content;min-width: 150px">
    <div>FILTER OPTIONS</div>
    <div class="d-flex align-items-center mt-3" style="height:60px">
      <i class="mr-3 fas fa-list d-none d-xl-block mr-3" style="font-size: 1.3rem;" />
      <mdb-select
        v-model="historyTypeOptions"
        placeholder="Type"
        label="History Type"
        size="sm"
        style="width:100%;"
        class="m-0 mr-3 addingSpotlightForm"
      />
    </div>
    <div class="d-flex align-items-center" style="height:60px">
      <i class="mr-3 fas fa-calendar d-none d-xl-block mr-3" style="font-size: 1.3rem;" />
      <mdb-select
        v-model="yearOptions"
        placeholder="Year"
        size="sm"
        label="Year"
        style="width:100%;"
        class="m-0 mr-3 addingSpotlightForm"
      />
    </div>
    <div class="d-flex align-items-center" style="height:60px">
      <i class="mr-3 fas fa-calendar d-none d-xl-block mr-3" style="font-size: 1.3rem;" />
      <mdb-select
        v-model="monthOptions"
        placeholder="Month"
        size="sm"
        label="Month"
        style="width:100%;"
        class="m-0 mr-3 addingSpotlightForm"
      />
    </div>
  </div>
</template>

<script>
import { useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'

export default {
  name: 'FilterCandidateHistory',
  data () {
    return {
      historyTypeOptions: [
        { text: 'All', value: 'all', selected: true },
        { text: 'Completed Video CV', value: 'VideoCV' },
        { text: 'Job Application', value: 'JobApplication' },
        { text: 'One-Way Completed', value: 'OneWayCompleted' },
        { text: 'Live Interview Completed', value: 'TwoWayCompleted' },
        { text: 'Tagged In MeetNow', value: 'TaggedInMeetNow' }
      ],
      yearOptions: [
        { text: 'All', value: 0, selected: true }
      ],
      monthOptions: [
        { text: 'All', value: '00', selected: true },
        { text: 'January', value: '01' },
        { text: 'February', value: '02' },
        { text: 'March', value: '03' },
        { text: 'April', value: '04' },
        { text: 'May', value: '05' },
        { text: 'June', value: '06' },
        { text: 'July', value: '07' },
        { text: 'August', value: '08' },
        { text: 'September', value: '09' },
        { text: 'October', value: '10' },
        { text: 'November', value: '11' },
        { text: 'December', value: '12' }
      ]
    }
  },
  setup () {
    const candidateStore = useCandidate()
    const { selectedYear, selectedMonth,selectedHistoryType } = storeToRefs(candidateStore)
    return { selectedYear, selectedMonth, selectedHistoryType }
  },
  computed: {
    selectedHistoryTypeLocal () {
      try {
        return this.historyTypeOptions.filter(h => h.selected)[0].value
      } catch { return 'all' }
    },
    selectedMonthLocal () {
      try {
        return this.monthOptions.filter(h => h.selected)[0].value
      } catch { return '00' }
    },
    selectedYearLocal () {
      try {
        return this.yearOptions.filter(h => h.selected)[0].value
      } catch { return 0 }
    }
  },
  watch: {
    selectedHistoryTypeLocal: {
      handler () {
        this.selectedHistoryType = this.selectedHistoryTypeLocal
      },
      immediate: true
    },
    selectedYearLocal: {
      handler () {
        this.selectedYear = this.selectedYearLocal
      },
      immediate: true
    },
    selectedMonthLocal: {
      handler () {
        this.selectedMonth = this.selectedMonthLocal
      },
      immediate: true
    }
  },
  created () {
    this.createYearfilters()
  },
  methods: {
    createYearfilters () {
      let year = new Date().getFullYear()
      while (year > 2019) {
        this.yearOptions.push({ text: String(year), value: year })
        year--
      }
    }
  }
}
</script>
