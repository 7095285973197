<template>
  <!--documents-->
  <div style="overflow-x:hidden;" class="flex-grow-1 reviewModalRHTab">
    <div v-show="noDocs === false">
      <b-row
        v-for="file in candidateDocs"
        :key="file.DocumentId"
        no-gutters
        style="border: solid 1px #c0c0c0;"
        class="mx-2 mt-2 text-bold text-black rounded text-center py-2 bg-gray-100 px-2 px-md-3 flex-nowrap"
      >
        <div class="align-self-center flex-grow-0" style="text-align: left;font-size: 1rem;font-weight: bold;">
          <i
            v-if="file.DocumentExtension == '.doc' || file.DocumentExtension == '.docx'"
            class="col-span-1 fas fa-2x text-left fa-file-word mr-3"
            style="font-size:1.2rem;"
          />
          <i
            v-if="file.DocumentExtension == '.pdf' "
            class="col-span-1 fas fa-2x text-left fa-file-pdf mr-3"
            style="font-size:1.2rem;"
          />
          <i
            v-if="file.DocumentExtension == '.jpg' || file.DocumentExtension == '.jpeg' || file.DocumentExtension == '.JPG' || file.DocumentExtension == '.png'"
            class="col-span-1 fas fa-2x text-left fa-file-image mr-3"
            style="font-size:1.2rem;"
          />
        </div>
        <b-col
          v-if="mobileView"
          cols="5"
          sm="auto"
          class="align-self-center text-nowrap overflow-hidden"
          style="text-align:left;font-size:smaller;"
        >
          {{ file.DocumentName }}
        </b-col>
        <b-col
          v-else
          cols="2"
          sm="auto"
          class="align-self-center text-nowrap overflow-hidden"
          style="text-align:left;font-size:smaller;"
        >
          {{ file.DocumentName }}  |
        </b-col>
        <b-col
          v-if="!mobileView"
          cols="3"
          sm="auto"
          class="align-self-center font-extrabold ml-2"
        >
          {{ file.DocumentType }}
        </b-col>
        <b-col
          cols="auto"
          class="flex-grow-1"
          style=""
        />
        <b-col class="align-self-center text-right flex-grow-0" style="">
          <b-row
            no-gutters
            class="flex-nowrap"
            align-v="center"
          >
            <b-col
              class="d-none d-sm-block"
              sm="md"
              lg="6"
              style="align-self:center"
            >
              <mdb-btn
                class="btn m-0"
                size="sm"
                style="padding: 0.5rem 1.1rem;"
                @click="openViewFileModal(file)"
              >
                View
              </mdb-btn>
            </b-col>
            <b-col sm="md" lg="6">
              <mdb-popover
                ref="popover"
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;"
                class="grey-text"
              >
                <div slot="body">
                  <h6 class="d-flex" style="align-items:center">
                    <i class="fa fa-download grey-link " style="margin-right:7px;" /><a :href="file.DocUrl" class="grey-link"> Download</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  style="transform: translateX(15px); color:#555;font-size:1rem;"
                  class="ellipsisButtonHover mr-2 ml-lg-2"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-show="noDocs === true && !loading">
      <p style="padding:10px;font-size:small;" class="font-weight-bold grey-text">
        No documents have been uploaded for this candidate.
      </p>
    </div>

    <CandidateFileViewer
      :view-file-modal="viewFileModal"
      :candidate-first-name="candidate.FirstName"
      :candidate-surname="candidate.LastName"
      :candidate-profile-picture-url="candidate.ProfilePicture"
      :file="file"
      :candidate-id="candidate.CandidateId"
      :current-i-d-check-status="candidate.IDChecked"
      @changeIDCheckStatus="$emit('changeIDCheckStatus', $event)"
      @closeViewFileModal="viewFileModal = false"
    />
  </div>
</template>

<script>
import { useUserData, useStatus } from '@/pinia'
import { useMobile } from '@/composables/useMobile'
import roleV2Service from '@/api/services/roleV2.service'

import CandidateFileViewer from '@/components/misc/candidateViewFileModal.vue'

export default {
  name: 'Documents',
  components: { CandidateFileViewer },
  props: {
    candidateApplication: {
      type: Object,
      default: () => {}
    },
    candidate: {
      type: Object,
      default: () => {}
    },
    currentQuestionId: {
      type: Number,
      default: 0
    }
  },
  setup () {
    const userStore = useUserData()

    const { showGenericErrorModal } = useStatus()
    const { mobileView } = useMobile()

    return {
      showGenericErrorModal,
      mobileView
    }
  },
  data () {
    return {
      viewFileModal: false,
      loading: false,
      file: {},
      candidateDocs: []
    }
  },
  computed: {
    noDocs () {
      return this.candidateDocs.length === 0
    }
  },
  created () {
    this.getDocs()
  },
  methods: {
    async openViewFileModal (file) {
      this.file = file
      this.viewFileModal = true
    },
    async getDocs () {
      try {
        this.loading = true
        const response = await roleV2Service.getCandidateDocs(this.candidateApplication.CandidateId)
        this.candidateDocs = response.data
        this.candidateDocs.forEach(d => {
          if (d.DocumentType == 0) {
            d.DocumentType = 'CV'
          } else if (d.DocumentType == 1) {
            d.DocumentType = 'Identification'
          } else if (d.DocumentType == 2) {
            d.DocumentType = 'Right To Work'
          } else if (d.DocumentType == 3) {
            d.DocumentType = 'Presentation'
          } else if (d.DocumentType == 4) {
            d.DocumentType = 'Covering Letter'
          } else if (d.DocumentType == 5) {
            d.DocumentType = 'Video'
          } else if (d.DocumentType == 6) {
            d.DocumentType = 'Video CV'
          } else if (d.DocumentType == 7) {
            d.DocumentType = 'Other'
          }
        })
      } catch (err) {
        this.showGenericErrorModal({ showing: true })
      }
      this.loading = false
    }
  },
}
</script>
