<template>
  <div class="leftColumnBoilerPlate d-none d-md-block">
    <div style="width:100%;height:100%;" class="d-flex flex-col">
      <div class="font-bold text-left text-gray-600 leftColHeader" @click="getRecentRolesLocal()">
        Recent Roles
        <i v-show="loadingRecentRoles" class="fas fa-spin fa-sync" />
        <i v-show="!loadingRecentRoles" class="fas fa-sync cp" />
      </div>
      <div class="leftColMainContent p-2 mb-3">
        <transition-group
          tag="ul"
          name="slide-in"
          :style="{ '--total': recentRoles.length }"
          style="height:100%;"
        >
          <li
            v-for="(role, i) in recentRoles"
            :key="role.JobId"
            style="margin:10px;"
            :style="{'--i': i}"
          >
            <Tile :role="role" />
          </li>
        </transition-group>
      </div>
      <div
        class="d-flex flex-wrap align-items-center justify-center leftColBottomButtonPanel"
        style="margin-bottom:3px;"
        :class="{ 'notBarActive': userData.aut && userData.inf > 0 }"
      >
        <mdb-btn
          size="sm"
          class="btn btn-outline my-2 my-md-1 mx-2"
          style="min-width:110px;width:fit-content;padding: 0.5rem 1.1rem;"
          title="Go back to the roles page?"
          @click="viewRoles()"
        >
          VIEW ROLES
        </mdb-btn>
        <mdb-btn
          v-if="userData.rle !== 'Inviter'"
          size="sm"
          class="btn btn-outline-web my-2 my-md-1 mx-2"
          style="min-width:110px;width:fit-content;padding: 9px 1.1rem;"
          @click="addRole()"
        >
          <i class="fa fa-plus-circle" /> ADD
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useWorkflow, useRoles } from '@/pinia'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import Tile from './leftColumnTile.vue'

export default {
  name: 'LeftColumnRolesV2',
  components: {
    Tile
  },
  setup () {
    const userStore = useUserData()
    const { userData } = storeToRefs(userStore)

    const workflowStore = useWorkflow()
    const { resetRoleData } = storeToRefs(workflowStore)

    const roleStore = useRoles()
    const { recentRoles, getRecentRoles, loadingRecentRoles } = storeToRefs(roleStore)

    return {
      resetRoleData,
      userData,
      recentRoles,
      getRecentRoles,
      loadingRecentRoles
    }
  },
  data () {
    return {
      importAndInviteModal: false,
      selectedRoleId: 0,
    }
  },
  created () {
    this.getRecentRolesLocal()
  },
  methods: {
    async getRecentRolesLocal () {
      await this.getRecentRoles()
    },
    sharingModalInitialisation (args) {
      this.$emit('initialiseSharingModal', args)
    },
    viewRoles () {
      window.location.reload()
    },
    addRole () {
      this.resetRoleData()
      this.$router.push('/roles/add_edit/role_setup/0?page=about_this_role').catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-plus-circle {
  margin-right:5px;
}
</style>
