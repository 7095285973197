<template>
  <div>
    <mdb-modal
      id="taggingUntaggingAssessorModalShowing"
      :show="taggingUntaggingAssessorModalShowing"
      size="lg"
      @close="$emit('taggingUntaggingAssessorModalShowing', false)"
    >
      <mdb-modal-header class="align-items-center justify-content-between">
        <div class="d-flex align-items-center " style="height:26px">
          <div style="margin-right: 10px;font-weight:bold;">
            TAGS
          </div>
          <!-- HOSTS -->
          <div
            v-if="recording.Host"
            class="mr-3 d-flex align-items-center"
            style="width:fit-content;height:30px"
          >
            <img
              :title="`Host - ${recording.Host.Name}`"
              :src="recording.Host.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
            >
          </div>
          <!-- ASSESSOR -->
          <div
            v-if="recording.Assessors != null && recording.Assessors.length != 0"
            class="mr-3 d-flex "
            style="height:30px; width:fit-content;"
          >
            <img
              v-for="(assessor,i) in recording.Assessors"
              :key="i"
              :class="{ 'borderForNoProfilePic' : assessor.ProfilePicture.includes('candidate-silhouette'), 'newAssessorLoadedBorder': newAssessorLoaded && assessor.TargetId === newlyAddedAssessorId }"
              class="newAssessor"
              :title="`Assessor - ${assessor.Name}`"
              :src="assessor.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
              :style="`transform:translateX(-${i * 4}px)`"
            >
          </div>
          <!-- CANDIDATES -->
          <div
            v-if="recording.Candidate != undefined && recording.Candidate != null"
            class="mr-3 d-flex align-items-center"
            style="width:fit-content;height:30px"
          >
            <img
              v-show="recording.Candidate.Name != ''"
              :title="`Candidate - ${recording.Candidate.Name}`"
              :src="recording.Candidate.ProfilePicture"
              style="height:30px;width:30px; border-radius:50%;border:solid 2px;cursor:pointer;"
            >
          </div>
          <!-- GUESTS -->
          <div class="mr-3 d-flex align-items-center justify-items-center" style="width:fit-content;height:30px">
            <span
              v-for="(guest,i) in recording.Guests"
              :key="i"
              :title="`Guest - ${i+1}`"
              :style="`transform:translate(-${i * 5}px, 5px)`"
              style="height:30px;"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                style="height:10px;width:10px; background:#d3d3d3;border-radius:50%;border:solid 2px;transform:scale(1.5)"
                :class="newAssessorLoaded ? 'newAssessorLoadedBorder' : ''"
                class="p-1"
              />
            </span>
          </div>
        </div>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="d-flex align-items-center justify-content-start mt-3">
          <FontAwesomeIcon icon="fa-solid fa-user-tag" class="d-inline mr-2" />
          <mdb-switch
            v-model="tagging"
            style="transform:scale(0.9)"
            off-label=""
            on-label=""
            class="m-0 mt-1 mr-2"
          />
          <div style="font-size:0.8rem;color:#757575">
            {{ tagging ? "Tag An Assessor" : "Untag an Assessor" }}
          </div>
        </div>
        <div v-if="tagging" class="mt-4">
          <div style="height:150px;overflow-y:scroll;border:solid #C0C0C0 1px;">
            <div v-if="filteredAssessors.length > 0 && filteredAssessors != null">
              <b-row
                v-for="assessor in filteredAssessors"
                :key="assessor.Id"
                align-h="between"
                align-v="center"
                no-gutters
                style="border-radius:5px;height:40px;border:2px #C0C0C0 solid;background:#ffffff;cursor:pointer;"
                class="p-2 m-2 borderColorOnHover"
                @click="selectedPerson = [assessor]"
              >
                <div style="height:100%;" class="d-flex align-items-center">
                  <FontAwesomeIcon
                    icon="fa-solid fa-user"
                    class="d-inline mr-3"
                    style="height:25px;width:25px;border-radius:50%;object-fit:cover;"
                  />
                  <h5 class="text-left font-weight-bold d-inline">
                    {{ assessor.FirstName }} {{ assessor.Surname }}
                  </h5>
                </div>
              </b-row>
            </div>
            <div v-if="filteredAssessors.length == 0 && filteredAssessors != null" class="mt-3">
              <p class="grey-text text-center">
                No Assessors Found
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="currentlyTagged.length > 0" class="d-flex align-items-center justify-content-center">
            <div
              v-for="(tagged) in currentlyTagged"
              :key="tagged.TargetId"
              style="cursor:pointer;"
              class="selectedToUntagEase mr-3"
              @click="selectToUntag(tagged.TargetId)"
            >
              <div style="box-sizing:content-box" class="d-flex flex-column align-items-center justify-content-center p-2 borderHover">
                <img :src="tagged.ProfilePicture" style="width:60px; height:60px; object-fit:cover; border-radius:50%;border:solid;">
                <div class="mt-1 text-black" style="font-size:0.9rem;">
                  {{ tagged.Name }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center text-left text-black mt-5"
            style="font-size:0.9rem"
          >
            No Assessors to Untag
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer class="justify-content-end">
        <div v-if="tagging && selectedPerson != null">
          <mdb-btn
            v-show="!buttonClick"
            size="sm"
            style="width:100px;"
            @click.native="tagCandidateOrAssessor()"
          >
            TAG
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
        <div v-if="!tagging && noOneSelected">
          <mdb-btn
            v-show="!buttonClick"
            :disabled="currentlyTaggedPerson.length == 0"
            size="sm"
            style="width:100px;"
            @click.native="tagCandidateOrAssessor()"
          >
            UNTAG
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            size="sm"
            style="width:100px;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { useMeetNow, useUserData } from '@/pinia'
import { storeToRefs } from 'pinia'

import roleService from '@/api/services/role.service'
import meetNowService from '@/api/services/meetnow.service'

export default {
  name: 'TaggingUntaggingAssessorModal',
  props: {
    recording: {
      default: () => {},
      type: Object
    },
    taggingUntaggingAssessorModalShowing: Boolean
  },
  setup () {
    const { addOrUpdateRecording } = useMeetNow()

    const userStore = useUserData()
    const { authData, userData } = storeToRefs(userStore)

    return {
      addOrUpdateRecording,
      authData,
      userData
    }
  },
  data () {
    return {
      assessors: [],
      buttonClick: false,
      loading: false,
      currentlyTaggedPerson: {},
      candidateDetailsSearch: '',
      tagging: true,
      selectedPerson: null,
      newAssessorLoaded: false,
      newlyAddedAssessorId: ''
    }
  },
  computed: {
    currentlyTagged () {
      if (this.recording.Assessors) { return this.recording.Assessors }
      return []
    },
    noOneSelected () {
      return this.assessors.filter(a => a.selected).length === 0
    },
    filteredAssessors () {
      try {
        const alreadyTaggedPeople = []
        alreadyTaggedPeople.push(...this.recording.Assessors.map(a => { return a.TargetId }))
        alreadyTaggedPeople.push(this.recording.Host.TargetId)
        return this.assessors.filter(a => !alreadyTaggedPeople.includes(a.TargetId))
      }
      catch {
        return this.assessors
      }
    }
  },
  created () {
    this.currentlyTaggedPerson = this.currentlyTagged[0]
    this.getAssessors()
  },
  methods: {
    selectToUntag (id) {
      this.currentlyTaggedPerson = this.currentlyTagged.filter(c => c.TargetId === id)[0]
    },
    async getAssessors () {
      this.buttonClick = true
      try {
        const res = await roleService.getHostAssessors()
        this.assessors = res.data
      } catch (err) {
        //?
      }
      this.buttonClick = false
    },
    async tagCandidateOrAssessor () {
      this.buttonClick = true
      const formData = new FormData()
      formData.append('isCandidate', false)
      if (this.tagging) {
        formData.append('targetId', this.selectedPerson[0].Id)
        this.newlyAddedAssessorId = this.selectedPerson[0].Id
      } else {
        formData.append('targetId', this.currentlyTaggedPerson.TargetId)
        this.newlyAddedAssessorId = this.currentlyTaggedPerson.TargetId
      }
      formData.append('recordingId', this.recording.RecordingId)
      formData.append('tagging', this.tagging)
      try {
        const res = await meetNowService.tagCandidateOrAssessor(formData)
        this.addOrUpdateRecording(res.data)
      } catch (err) {
        //?
      }
      this.currentlyTaggedPerson = this.currentlyTagged[0] || []
      this.buttonClick = false
      this.newAssessorLoaded = true
      setTimeout(() => { this.newAssessorLoaded = false }, 3000)
      this.getAssessors()
    }
  }
}
</script>

<style scoped>
.newAssessor {
  box-shadow: 0px 0px 2px 3px var(#ffffff);
  border-radius: 50%;
  transition: all ease 1s;
}
.newAssessorLoadedBorder.newAssessor {
  box-shadow: 0px 0px 2px 3px var(--pr-color);
  border-radius: 50%;
  transition: box-shadow 1s ease 0.05s;
}
</style>

<style lang="scss">
.selectedToUntagEase {
  transition: 0.5s ease;
}
.borderHover {
  border: 2px solid #fff;
}
.borderHover:hover {
  border: 2px solid var(--pr-color);
}

@media (max-width: 576px) {
  #taggingUntaggingAssessorModalShowing .modal-dialog .modal-content {
    margin:5px !important;
    width:calc(100vw - 10px) !important;
    height: calc(100vh - 10px) !important;
  }
}
</style>
