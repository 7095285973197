<template>
  <div
    class="col-sm mb-0 p-0"
    style="overflow-x:hidden;overflow-y:hidden;height:100% !important"
    :class="{'shiftMainPageRight': mobileView === false && (leftColumnActive === false || leftColumnActive === undefined)}"
  >
    <!--view all candidates-->
    <div v-if="view == 'ViewAllCandidates'">
      <ViewAllCandidates
        @errorAlert="errorAlert($event)"
        @openShareModal="sharingModalShowing = true"
      />
    </div>

    <!--import candidates-->
    <div v-if="view == 'ImportCandidates'">
      <ImportCandidates
        @getCandidates="candidates = []"
        @errorAlert="errorAlert($event)"
      />
    </div>

    <div
      v-if="view !== 'ViewAllCandidates' && view !== 'ImportCandidates'"
      class="tabWidth p-0 candidatesScrollContainer overFlowY"
      style="height:calc(100vh-80px);overflow-y:auto"
    >
      <!--header section-->
      <b-row
        no-gutters
        align-h="between"
        align-v="center"
        class="flex-nowrap candidatesScrollContent"
        style="overflow:hidden;width:100%;"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="position:absolute;overflow:hidden;transform:translateY(-85px);"
          width="1600px"
          height="300px"
          viewBox="0 0 1600 300"
        ><rect
          fill="#ffaa00"
          width="900"
          height="300"
        /><g><path :fill="'var(--pr-color)'" d="M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z" /><path :fill="`${userData.lbl.PrimaryColour}80`" d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z" /></g></svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="position:absolute;left:1600px;overflow:hidden;transform:translateY(-85px) rotateY(180deg)"
          width="1600px"
          height="300px"
          viewBox="0 0 1600 300"
        ><rect
          fill="#ffaa00"
          width="900"
          height="300"
        /><g><path :fill="'var(--pr-color)'" d="M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z" /><path :fill="`${userData.lbl.OpacityColour}`" d="M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z" /><path :fill="`${userData.lbl.PrimaryColour}80`" d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z" /><path :fill="`${userData.lbl.PrimaryColour}`" d="M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z" /></g></svg>
        <b-col xs="auto" class="p-2 p-md-3">
          <SlideFade>
            <div v-if="newCand">
              <b-row
                no-gutters
                class="flex-nowrap"
                style="cursor:pointer;"
                :title="candidateToEdit.FirstName + ' ' + candidateToEdit.LastName + ' (' + candidateToEdit.CandidateId + ')'"
              >
                <div
                  style="height:95px;width:95px;border:2px white solid; border-radius:50%;display:flex;align-items:center;justify-content:center;"
                  class="pPic"
                  @click="editProfilePicture()"
                >
                  <div
                    v-if="candidateToEdit.ProfilePicture === '' || candidateToEdit.ProfilePicture === undefined || candidateToEdit.ProfilePicture?.includes('candidate-silhouette') || !candidateToEdit.ProfilePicture"
                    style="border-radius:50%;position:relative;cursor:pointer;color:white;"
                    class="p-1"
                  >
                    <span v-if="candidateToEdit.CandidateId > 0">
                      <i class="fa fa-camera fa-2x" style="border-radius:50%;" />
                      <i class="fa fa-plus" style="position:absolute; right:-8%;top:-8%;transform:scale(0.75)" />
                    </span>
                    <span v-else>
                      <i class="fa fa-user fa-2x" style="border-radius:50%;" />
                    </span>
                  </div>
                  <div
                    v-else
                    style="border-radius:50%; cursor:pointer;position:relative;"
                    class="profilePicPresent d-flex align-items-center justify-content-center p-1"
                  >
                    <img :src="candidateToEdit.ProfilePicture" style="object-fit:cover;display:block;border-radius:50%;width:85px;height:85px;">
                    <div class="position-absolute d-flex align-items-center justify-content-center editBlock" style="height:90px;width:90px;background:#3333334D;border-radius:50%;">
                      <i class="fa fa-edit" style="color:#fff" />
                    </div>
                  </div>
                </div>
                <b-col style="align-self:center;letter-spacing:1px;" class="titleFontSize text-left ml-4 font-weight-bold">
                  <span class="d-flex align-items-center">
                    <div :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }">{{ candidateToEdit != null ? candidateToEdit.FirstName : '' }} {{ candidateToEdit != null ? candidateToEdit.LastName : '' }}</div>
                    <IDCheckedBadge
                      v-if="candidateToEdit.IDChecked"
                      class="ml-2"
                    />
                  </span>
                  <div
                    v-show="mobileView === false"
                    v-if="candidateToEdit.Following"
                    :style="{ 'background': `${userData.lbl.SecondaryColour} !important` }"
                    style="padding:1px;margin-top: -5px;font-size: 14px; margin-top: 0px;;width: 95px;border-radius: 5px;"
                    @click="unFollowCandidate(candidateToEdit)"
                  >
                    <mdb-icon
                      style="margin-left:3px;"
                      icon="star"
                      :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }"
                      title="Unfollow candidate"
                    />
                    <span style="font-size:12px;margin-left:3px;">Following</span>
                  </div>
                  <div
                    v-show="mobileView === false"
                    v-else
                    :style="{ 'background': `${userData.lbl.SecondaryColour} !important` }"
                    style="padding:1px;margin-top: -5px;font-size: 14px; margin-top: 0px;width: 75px;border-radius: 5px;"
                    @click="followCandidate(candidateToEdit)"
                  >
                    <mdb-icon
                      style="margin-left:3px;"
                      far
                      icon="star"
                      :style="{ 'color': `${userData.lbl.PrimaryColour} !important` }"
                      title="Follow candidate"
                    />
                    <span style="font-size:12px;margin-left:3px;">Follow</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </SlideFade>
        </b-col>
        <div
          v-if="candidateToEdit.CandidateId !== 0"
          style="color:#333!important;cursor:pointer;font-weight: bold;margin-top: -1px;z-index:3"
          class="d-flex mr-3 flex-column align-items-end"
        >
          <mdb-popover
            ref="popover"
            trigger="click"
            :options="{placement: 'left'}"
            style="cursor:pointer;display:none;"
            class="grey-text share-parent"
          >
            <div slot="body">
              <QuickSharePopoverButton
                :candidate-id="candidateToEdit.CandidateId"
                @closePopover="$refs.popover.doClose()"
              />
              <div class="email-share pop-share">
                <h6 @click="launchSharingModal()">
                  <i class="fa fa-envelope" />Share
                </h6>
              </div>
            </div>
            <mdb-icon
              v-if="(mobileView === false)"
              slot="reference"
              title="Share"
              class="mr-2 share-icon"
              :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
              icon="share-alt"
              style="margin-right: 0px !important;"
            />
            <mdb-icon
              v-else
              slot="reference"
              title="Share"
              class="mr-2"
              :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
              icon="share-alt"
              style="margin-right: 9px !important;"
            />
          </mdb-popover>
          <div
            class="d-flex"
            title="Meet Now"
            :class="{ 'mTop5': mobileView }"
          >
            <FontAwesomeIcon
              v-show="mobileView === false"
              icon="fa-solid fa-user-group"
              :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
              class="mr-2"
            />
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'left'}"
            >
              <div slot="body">
                <h6
                  style="margin-left:-10px;margin-top:-5px;padding:5px 12px;"
                  class="text-nowrap"
                  @click="showInstantAndScheduledModal(true)"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-video"
                    class="ml-1 grey-link "
                    style="margin-right:7px;"
                  /><a class="grey-link">Instant Meet</a>
                </h6>
                <h6
                  style="padding:5px 12px;margin-left:-10px"
                  class="text-nowrap"
                  @click="showInstantAndScheduledModal(false)"
                >
                  <FontAwesomeIcon
                    icon="fa-calendar-clock fa-solid"
                    class="ml-1 grey-link"
                    style="margin-right:10px;"
                  /><a class="grey-link">Scheduled Meeting</a>
                </h6>
              </div>
              <h3
                v-if="mobileView"
                slot="reference"
                :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-user-group"
                  :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
                  class="mr-2"
                />
              </h3>
              <h3
                v-else
                slot="reference"
                :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
              >
                MEET NOW
              </h3>
            </mdb-popover>
          </div>
          <div v-show="mobileView">
            <div
              v-if="candidateToEdit.Following"
              style="margin-top:15px;"
              title="Unfollow candidate"
              class="d-flex"
              @click="unFollowCandidate(candidateToEdit)"
            >
              <mdb-icon
                icon="star"
                :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
                class="mr-2"
              />
            </div>
            <div
              v-else
              style="margin-top:15px;"
              title="Follow candidate"
              class="d-flex"
              @click="followCandidate(candidateToEdit)"
            >
              <mdb-icon
                far
                icon="star"
                :style="{ 'color': `${userData.lbl.SecondaryColour} !important` }"
                class="mr-2"
              />
            </div>
          </div>
        </div>
      </b-row>

      <mdb-container style="max-width:100%;height:calc(100vh - 210px);">
        <mdb-tab
          tabs
          :style="{ 'background': `${userData.lbl.PrimaryColour}bb !important;` }"
          justify
        >
          <mdb-tab-item
            v-if="mobileView && candidateToEdit.CandidateId > 0"
            :active="activeTab === -1"
            :class="{ 'tab-border-top': activeTab !== -1 }"
            @click.native.prevent="viewAll()"
          >
            <span class="tabMobile"><mdb-icon icon="fa fa-users" title="View All Candidates" /></span>
          </mdb-tab-item>
          <mdb-tab-item
            v-if="candidateToEdit.CandidateId !== 0"
            :active="activeTab === 0"
            :class="{ 'tab-border-top': activeTab !== 0 }"
            @click.native.prevent="activeTab = 0"
          >
            <span class="tabDesktop">PROFILE</span>
            <span class="tabMobile"><mdb-icon icon="fa fa-user" title="Profile" /></span>
          </mdb-tab-item>
          <mdb-tab-item
            v-if="candidateToEdit.CandidateId !== 0"
            :active="activeTab === 1"
            :class="{ 'tab-border-top-left': activeTab !== 1 }"
            @click.native.prevent="activeTab = 1"
          >
            <span class="tabDesktop">VIDEO CV</span>
            <span class="tabMobile"><mdb-icon icon="fa fa-video" title="Video CV" /></span>
          </mdb-tab-item>
          <mdb-tab-item
            v-if="candidateToEdit.CandidateId !== 0"
            :active="activeTab === 2"
            :class="{ 'tab-border-top-left': activeTab !== 2 }"
            @click.native.prevent="activeTab = 2"
          >
            <span class="tabDesktop">FILES</span>
            <span class="tabMobile"><mdb-icon icon="fa fa-file" title="Files" /></span>
          </mdb-tab-item>
          <mdb-tab-item
            v-if="candidateToEdit.CandidateId !== 0"
            :active="activeTab === 3"
            :class="{ 'tab-border-top-left': activeTab !== 3 }"
            @click.native.prevent="activeTab = 3"
          >
            <span class="tabDesktop">HISTORY</span>
            <span class="tabMobile"><mdb-icon icon="fa fa-history" title="History" /></span>
          </mdb-tab-item>
          <mdb-tab-item
            v-if="candidateToEdit.CandidateId !== 0"
            :active="activeTab === 4"
            :class="{ 'tab-border-top-left': activeTab !== 4 }"
            @click.native.prevent="activeTab = 4"
          >
            <span class="tabDesktop">NOTES</span>
            <span class="tabMobile"><mdb-icon icon="fa fa-sticky-note" title="Notes" /></span>
          </mdb-tab-item>
        </mdb-tab>
        <mdb-tab-content style="height:calc(100vh - 250px);margin-top:20px">
          <!--add/edit candidate-->
          <mdb-tab-pane
            v-if="activeTab === 0"
            class="fade"
            style="height:100%;overflow-y:auto;"
          >
            <Profile
              @followCandidate="followCandidate($event)"
              @addCandidateToRecentCandidates="addCandidateToRecentCandidates($event)"
              @redirectAfterSaveHandler="redirectAfterSaveHandler"
              @errorAlert="errorAlert($event)"
            />
          </mdb-tab-pane>
          <!--video cv-->
          <mdb-tab-pane
            v-if="activeTab === 1"
            class="fade"
            style="height:100%;overflow-y:auto;"
          >
            <VideoCV @addCandidateToRecentCandidates="addCandidateToRecentCandidates($event)" />
          </mdb-tab-pane>
          <!--files-->
          <mdb-tab-pane
            v-if="activeTab === 2"
            class="fade"
            style="height:100%;overflow-y:auto;"
          >
            <Files @addCandidateToRecentCandidates="addCandidateToRecentCandidates($event)" />
          </mdb-tab-pane>
          <!--history-->
          <mdb-tab-pane
            v-if="activeTab === 3"
            class="fade"
            style="height:100%;overflow-y:auto;"
          >
            <div class="d-flex" style="width:100%;height:100%;">
              <div class="min-h-full py-3 py-md-4 w-1/1 px-3 text-black rounded flex-grow-1" style="background:rgb(225, 225, 225);min-height:400px;height:calc(100vh - 315px);overflow-y:auto;margin-bottom:110px;overflow-x:hidden;position:relative;">
                <HistoryContainer
                  :candidate-id="candidateToEdit.CandidateId"
                />
              </div>
              <FontAwesomeIcon
                icon="fa-regular fa-circle-chevron-right"
                size="1x"
                :class="{ 'rotatedChevron' : !historyFilterShowing }"
                class="historyChevron d-none ml-3 mr-1 d-sm-flex d-md-none d-lg-flex cp mt-2"
                @click="toggleHistoryFilterShowing()"
              />
              <div
                :style="{ 'width': historyFilterShowing ? '25%' : '0'}"
                style="transition: width 0.4s ease;"
                :class="{'ml-4': historyFilterShowing }"
                class="d-none d-sm-flex d-md-none d-lg-flex mt-1"
              >
                <FilterCandidateHistory style="width:100%" />
              </div>
            </div>
          </mdb-tab-pane>
          <!--notes-->
          <mdb-tab-pane
            v-if="activeTab === 4"
            class="fade"
            style="height:100%;overflow-y:auto;"
          >
            <Notes />
          </mdb-tab-pane>
        </mdb-tab-content>
      </mdb-container>
    </div>

    <!--error modal-->
    <mdb-modal
      id="errorModal"
      :show="errorModal.showing"
      @close="setErrorModal(false, '', '')"
    >
      <mdb-modal-header>
        <mdb-modal-title>{{ errorModal.title.toUpperCase() }}</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          {{ errorModal.message }}
        </p>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="setErrorModal(false, '', '')"
        >
          OK
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--add import candidates modal-->
    <mdb-modal
      v-if="showAddUploadCandidateModal"
      id="showAddUploadCandidateModal"
      @close="showAddUploadCandidateModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>ADD OR IMPORT NEW CANDIDATES</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeightForRoles': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Select to add an individual candidate or upload candidates in bulk from a CSV file (max. {{ BULK_UPLOAD_LIMIT }}  records).
        </p>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          class="float-left"
          size="sm"
          @click.native="importCandidates()"
        >
          <i class="fa fa-users" /> IMPORT
        </mdb-btn>
        <mdb-btn
          class="float-right"
          size="sm"
          @click.native="addCandidate()"
        >
          <i class="fa fa-user" /> NEW
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--edit profile picture-->
    <mdb-modal
      id="showEditProfilePictureModal"
      :show="showEditProfilePictureModal"
      @close="showEditProfilePictureModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>EDIT PROFILE PICTURE</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body :class="{ 'modalHeight': mobileView }">
        <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
          Upload a new profile picture for candidate {{ candidateToEdit.FirstName }} {{ candidateToEdit.LastName }}? Please upload an image with the same dimensions in height and width, keeping the overall file size under 1Mb.
        </p>
        <div class="md-form form-sm" @click="launchCsvPicker">
          <div style="float:right;margin-right:23px;">
            <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
          </div>
          <input
            type="text"
            class="form-control"
            :placeholder="csvText"
            :value="file.name"
            readonly
          >
          <input
            ref="file"
            v-uploader
            type="file"
            style="display:none"
            accept=".jpg, .jpeg, .png"
          >
        </div>
      </mdb-modal-body>
      <mdb-modal-footer style="display:block;">
        <mdb-btn
          type="reset"
          class="float-left btn-outline"
          size="sm"
          style="width:100px;"
          @click="showEditProfilePictureModal = false"
        >
          CANCEL
        </mdb-btn>
        <mdb-btn
          v-show="!buttonModalClick"
          :disabled="file === ''"
          class="float-right"
          size="sm"
          style="width:100px;"
          @click.native="uploadProfilePicture();buttonModalClick = true;"
        >
          UPLOAD
        </mdb-btn>
        <mdb-btn
          v-show="buttonModalClick"
          class="float-right"
          size="sm"
          style="width:100px;"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <!--sharing modal-->
    <ShareModal
      :sharing-modal-showing="sharingModalShowing"
      @getCandidates="candidates = []"
      @closeSharingModal="sharingModalShowing = false"
    />

    <!-- AvatarCropper -->
    <div v-if="avatarCropperModalShowing">
      <AvatarCropper
        :loading-crop-image="loadingCropImage"
        :existing-pic="candidateToEdit.ProfilePicture"
        :avatar-cropper-modal-showing="avatarCropperModalShowing"
        @setLoadingCropImage="loadingCropImage = $event"
        @handleCrop="handleCrop($event)"
        @closeAvatarCroppingModal="avatarCropperModalShowing = false"
      />
    </div>

    <!--create meeting modal-->
    <div v-show="instantAndScheduledModalShowing">
      <InstantAndScheduledMeetingModal
        :meeting-is-instant="meetingIsInstant"
        :instant-and-scheduled-modal-showing="instantAndScheduledModalShowing"
        @setIsInstant="meetingIsIsstant = $event"
        @instantAndScheduledModalShowing="instantAndScheduledModalShowing = $event"
      />
    </div>

    <!--left comumn bottom menu-->
    <div
      v-if="mobileView === false && (leftColumnActive === false || leftColumnActive === undefined)"
      class="fixed bottom-0 leftColWidth leftColMenuAlign"
      style="margin-bottom:1px;background:#d1d1d1;margin-left:-30px;padding:9px;border-top: 1px solid #a1a1a1;z-index:1001;"
    >
      <div class="row">
        <div class="col-sm-12 text-center">
          <mdb-btn
            class="btn-outline"
            size="sm"
            style="width:110px;border:solid 2px !important;background:none !important;padding: 0.5rem 1.1rem;"
            title="View all candidates for this account?"
            @click="changeView('ViewAllCandidates')"
          >
            <i class="fas fa-th" /> VIEW ALL
          </mdb-btn>          
          <mdb-btn
            class="btn btn-solid-outline-web"
            size="sm"
            style="width:110px;padding: 0.5rem 1.1rem;"
            @click="addUploadCandidateModal();"
          >
            <i class="fas fa-plus-circle" /> ADD
          </mdb-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import candidateService from '@/api/services/candidate.service'
import logoutService from '@/api/services/logout.service'
import { logout } from '@/methods/auth.js'
import { useMobile } from '@/composables/useMobile'
import { useUserData, useCandidate, useNotification } from '@/pinia'
import { storeToRefs } from 'pinia'
import { BULK_UPLOAD_LIMIT } from '@/common/config.js'
import { mdbTab, mdbTabItem, mdbTabContent, mdbTabPane, mdbContainer, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbIcon } from 'mdbvue'
import Files from './Tabs/files.vue'
import HistoryContainer from '@/components/misc/History/CandidateHistory/candidateHistory.vue'
import Notes from './Tabs/notes.vue'
import Profile from './Tabs/profile.vue'
import VideoCV from './Tabs/VideoCV/videoCV.vue'
import ViewAllCandidates from './ViewAllCandidates/viewAllCandidates.vue'
import ImportCandidates from './importCandidates'
import ShareModal from './ShareModal/shareModal.vue'
import SlideFade from '@/components/UI/TransitionComponents/slideFade.vue'
import AvatarCropper from '@/components/AvatarCropper/avatarCropper.vue'
import InstantAndScheduledMeetingModal from './instantAndScheduledMeetingModal.vue'
import QuickSharePopoverButton from '@/components/misc/quickSharePopoverButton.vue'
import FilterCandidateHistory from './filterCandidateHistory.vue'
import IDCheckedBadge from '@/components/misc/IDCheckedBadge'

export default {
  name: 'Candidates',
  components: {
    AvatarCropper,
    SlideFade,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbIcon,
    mdbTab,
    mdbTabItem,
    mdbTabContent,
    mdbTabPane,
    mdbContainer,
    Files,
    HistoryContainer,
    Notes,
    Profile,
    VideoCV,
    ViewAllCandidates,
    ImportCandidates,
    ShareModal,
    InstantAndScheduledMeetingModal,
    QuickSharePopoverButton,
    FilterCandidateHistory,
    IDCheckedBadge
  },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, leftColumnActive } = storeToRefs(userStore)

    const notificationStore = useNotification()
    const { redirectToVideoCV,  redirectData } = storeToRefs(notificationStore)

    const candidateStore = useCandidate()
    const {
      updateCandidateFollowing,
      addCandidateToRecentCandidates,
      resetSingleShareModal,
      addOrUpdateCandidateAfterSave,
      resetCandidate,
      resetAllCandidates,
      setAllCandidates
    } = useCandidate()

    const {
      view,
      errorModal,
      candidates,
      candidateToEdit,
      candidateFiles,
      recentCandidates,
      candidatesAddedToShare,
      editingCandidate,
      redirectAfterSave,
      getCandidates
     } = storeToRefs(candidateStore)

     const { mobileView } = useMobile()

    return {
      authData,
      userData,
      leftColumnActive,
      redirectData,
      redirectToVideoCV,
      view,
      errorModal,
      candidates,
      candidateToEdit,
      recentCandidates,
      candidatesAddedToShare,
      editingCandidate,
      updateCandidateFollowing,
      addCandidateToRecentCandidates,
      resetSingleShareModal,
      addOrUpdateCandidateAfterSave,
      resetCandidate,
      resetAllCandidates,
      redirectAfterSave,
      candidateFiles,
      setAllCandidates,
      mobileView,
      getCandidates,
      BULK_UPLOAD_LIMIT
    }
  },
  data () {
    return {
      archiveModal: false,
      avatarCropperModalShowing: false,
      selectedCandidateId: null,
      errorModalTitle: '',
      buttonClick: false,
      buttonModalClick: false,
      activeTab: 0,
      newCand: false,
      showAddUploadCandidateModal: false,
      showEditProfilePictureModal: false,
      file: '',
      page: 0,
      loadingCropImage: false,
      csvText: 'Select a new image to upload',
      sharingModalShowing: false,
      loaded: true,
      instantAndScheduledModalShowing: false,
      meetingIsInstant: true,
      historyFilterShowing: true
    }
  },
  watch: {
    file (val) {
      this.$emit('file-chosen', val)
    },
    editingCandidate () {
      if (!this.redirectToVideoCV) {
        this.activeTab = 0
      }
      this.newCand = false
      this.newCand = true
    },
    candidateToEdit () {
      this.newCand = false
      this.newCand = true
    },
    instantAndScheduledModalShowing () {
      if (this.instantAndScheduledModalShowing) {
        this.$refs.popover.doClose()
      }
    }
  },
  created () {
    this.newCand = true
    this.activeTab = 0
    // checking if being redirected here from notifications
    if (this.redirectToVideoCV) {
      this.prepareForRedirectToVideoCV()
    }
    if (this.userData.rle == 'Inviter') {
      window.location.assign('/roles')
    }
  },
  methods: {
    logout,
    toggleHistoryFilterShowing () { this.historyFilterShowing = !this.historyFilterShowing },
    viewAll () {
      this.resetCandidate()
      this.view = 'ViewAllCandidates'
    },
    showInstantAndScheduledModal (isInstant) {
      this.meetingIsInstant = isInstant
      this.$nextTick(() => { this.instantAndScheduledModalShowing = true })
    },
    async prepareForRedirectToVideoCV () {
      this.editingCandidate = true
      try {
        const res = await candidateService.getCandidate(this.redirectData.CandidateId)
        this.candidateToEdit = { ...res.data }
      } catch (err) { 
        //?
       }
      this.view = 'Profile'
      this.loaded = true
      this.activeTab = 1
      this.redirectToVideoCV = false
    },
    followCandidate (candidate) {
      candidateService.followCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: true })
        }
      })
    },
    unFollowCandidate (candidate) {
      candidateService.unFollowCandidate(candidate.CandidateId).then((response) => {
        if (response.data === true) {
          this.updateCandidateFollowing({ candId: candidate.CandidateId, following: false })
        }
      })
    },
    launchSharingModal () {
      // make sure we reset first
      this.resetSingleShareModal()
      // if the selected candidate is not one of the ones added to "candidatesAddedToShare" this will replace other candidates with the newly selected one
      this.candidatesAddedToShare = this.candidatesAddedToShare.filter(c => c.CandidateId === this.candidateToEdit.CandidateId).length === 1 ? this.candidatesAddedToShare : [this.candidateToEdit]
      this.sharingModalShowing = true
    },
    launchCsvPicker () {
      this.$refs.file.click()
    },
    async redirectAfterSaveHandler () {
      this.sharingModalShowing = true
    },
    handleCrop (blob) {
      this.loadingCropImage = true
      this.file = blob
      this.uploadProfilePicture()
    },
    async uploadProfilePicture () {
      this.buttonModalClick = true
      var formData = new FormData()
      formData.append('UserName', this.userData.une)
      formData.append('CandidateId', this.candidateToEdit.CandidateId)
      formData.append('file', this.file)
      try {
        const response = await candidateService.profilePicture(formData)
        this.candidateToEdit.ProfilePicture = response.data
        this.file = ''
        this.showEditProfilePictureModal = false
        // updates recent candidates and all candidates
        this.addCandidateToRecentCandidates({ candId: this.candidateToEdit.CandidateId, token: this.authData.access_token })
        const updateCand = this.recentCandidates.filter(c => c.CandidateId === this.candidateToEdit.CandidateId)[0]
        this.addOrUpdateCandidateAfterSave(updateCand, this.candidateToEdit.CandidateId)
        this.buttonModalClick = false
      } catch (err) {
        console.log(Object.assign({}, err))
        this.errorModalTitle = 'Error!'
        this.errorAlert(err, 'Sorry an error has occurred!')
        this.showEditProfilePictureModal = false
        this.buttonModalClick = false
        this.file = ''
      }
      this.loadingCropImage = false
    },
    editProfilePicture () {
      if (this.candidateToEdit.CandidateId > 0) {
        this.avatarCropperModalShowing = true
      }
    },
    addUploadCandidateModal () {
      this.showAddUploadCandidateModal = true
    },
    formReset () {
      this.resetCandidate()
    },
    setErrorModal (showing, message, title) {
      this.errorModal = { showing, message, title }
    },
    addCandidate () {
      this.editingCandidate = false
      this.resetCandidate()
      this.view = 'Profile'
      this.candidateFiles = []
      this.showAddUploadCandidateModal = false
    },
    importCandidates () {
      this.view = 'ImportCandidates'
      this.showAddUploadCandidateModal = false
    },
    changeView (viewName) {
      this.loaded = false
      this.candidates = []
      this.view = viewName
      if (viewName === 'ViewAllCandidates') {
        if (window.location.hash === "#/candidates") {
          window.location.reload()
        } else {
          this.$router.push('/candidates?search=view_all').catch(() => {});
        }
      } else {
        this.view = viewName
      }
    },
    onArchiveModalHide () {
      this.selectedCandidateId = null
      this.archiveModal = false
    },
    onArchiveConfirm () {
      candidateService.archiveCandidate(this.selectedCandidateId).then(() => {
        this.errorModal = { showing: true, message: 'Candidate archived!', title: 'Success!' }
        this.candidates = []
      }).catch((error) => {
        this.errorAlert({ error, msg: 'Sorry an error has occurred!' })
      })
    },
    errorAlert ({ error, msg }) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = { showing: true, message: error.response.data.Message || 'Sorry, an error has occured', title: 'Error!' }
          } else if (error.response.data.error_description != null) {
            this.errorModal = { showing: true, message: error.response.data.error_description || 'Sorry, an error has occured', title: 'Error!' }
          } else {
            this.errorModal = { showing: true, message: error.response.data || 'Sorry, an error has occured', title: 'Error!' }
          }
        } else {
          this.errorModal = { showing: true, message: 'Sorry, an error has occured', title: 'Error!' }
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.errorModal = { showing: true, message: 'Authorization has been denied for this request. [ca]', title: 'Error!' }
          this.doLogOut()
        }
        if (msg === 'Sorry an error has occurred and the candidate cannot be deleted! Please check candidate is not currently invited to a role, if so please uninvite from the role first, then try deleting again.') {
          this.errorModal = { showing: true, message: msg || 'Sorry, an error has occured', title: 'Error!' }
        }
      } catch {
        this.errorModal = { showing: true, message: 'Sorry, an error has occured', title: 'Error!' }
      }
    },
    // reset state of stores?
    doLogOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    }
  }
}
</script>

<style lang="scss">
$primary-color: #495057;

.share-parent {
  margin-bottom: 5px;
  .share-icon {
    &::after {
      content: "SHARE";
      margin-left: 0.5rem;
      font-family: "Raleway", sans-serif;
    }
  }

  .pop-share {
    display: flex;
    align-items: center;
    h6 {
      color:$primary-color!important;
      flex:1;
      i {
        margin: 0 10px 0 0;
      }
    }
  }
}
.historyChevron {
  transform: rotate(0deg);
  transition: 0.5s ease-out;
  scale: 1.5;
}
.historyChevron.rotatedChevron {
  transform: rotate(-180deg);
  transition: 0.5s ease-out;
  scale: 1.5;
}
.mTop5 {
  margin-top: 5px;
}
.profilePicPresent .editBlock {
  opacity: 0;
  transition: all 0.5s;
}
.profilePicPresent:hover .editBlock {
  opacity: 1;
  transition: all 0.5s;
}
.page-link {
  border: 1px solid #dee2e6 !important;
}
table.table thead th {
  outline: none;
  cursor: pointer;
}
.form-control:focus {
  border-color: #d0d0d0;
  box-shadow: none;
}
ul.nav-tabs.nav-justified {
  background-color: var(--op-color) !important;
}
a.nav-link {
  color: #ffffff !important;
  white-space: nowrap;
  background-color: var(--op-color) !important;
}
a.nav-link.active {
  color: var(--bg-color) !important;
  background-color: #ffffff !important;
  font-weight: bold  !important;
}
.nav-tabs .nav-link {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  background-color: none !important;
}
.md-tabs .nav-link.active, .md-tabs .nav-item.open .nav-link {
  border-radius: 0px !important;
}
</style>

<style lang="scss" scoped>
.leftColMenuAlign {
  transform:translateX(-200px)
}
@media (min-width: 998px) {
  .leftColMenuAlign {
    transform:translateX(-300px)
  }
}
.tabWidth {
  width:100vw;
  padding: 0;
}
@media (min-width:768px) {
  .tabWidth {
    width:calc(100vw - 300px);
  }
}
@media (min-width:992px) {
  .tabWidth {
    width:calc(100vw - 400px);
  }
}
.canLoadingMargins {
  margin-top:33px !important;
  margin-left:48px !important;
}
.pBottom {
  padding-bottom: 100px;
}
.md-tabs {
  position: relative;
    z-index: 1;
    padding: 0rem !important;
    margin-right: -1rem !important;
    margin-bottom: -20px;
    margin-left: -1rem !important;
    background-color: #999;
    border: 0;
    border-radius: 0rem !important;
    box-shadow: none !important;
}
.video-modal-dialog {
  -webkit-transition: .3s;
  transition: .3s;
  max-width: calc(100% - 100px);
  margin-top: 50px;
  margin-left: 50px;
}
.video-modal-dialog-mobile {
  max-width: calc(100% - 10px);
  margin-top: 5px;
  margin-left: 5px;
}
.video-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  height: calc(100vh - 150px);
}
.video-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
  height: calc(100vh - 90px);
}
.video-modal-content-mobile {
  height: calc(100vh - 10px);
}
.border-center {
  width: 100%;
  position: relative;
}
.border-center:before {
  content: '';
  position: absolute;
  bottom: 50%;
  border-bottom: 1px #e1e1e1 solid;
  width: 100%;
  z-index:0;
}
.tab-border-top-left {
  border-left: solid 1px #e1e1e1;
  border-top: solid 1px #e1e1e1;
}
.tab-border-top {
  border-top: solid 1px #e1e1e1;
}
.canMargTopAlt {
  margin-top: -6px;
}
.canMargTopDeskAlt {
  margin-top: 10px;
}
</style>
