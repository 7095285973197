<template>
  <div
    class="min-h-full pt-2 pt-md-3 px-3 px-md-5 w-1/1 text-black rounded"
    style="height:calc(100vh - 190px);overflow-y:auto;margin-bottom:125px;overflow-x:hidden"
    :class="{ 'mob': mobileView }"
  >
    <div ref="scrollToDiv" style="height: 1px;">
      <div v-show="accountsLoaded === true" class="grey-text text-left">
        <div v-show="addEditAccountForm === false">
          <div
            v-if="accounts.length === 0"
            class="text-center grey-text"
            :class="{ 'm-a-left': mobileView }"
            style="font-size: 14px;margin-top: 50px;"
          >
            No accounts to display. Click <a
              class="primary-colour"
              style="font-weight:bold;"
              @click="addAccount()"
            ><em>here</em></a> to add a new account
            <span v-show="demoView">, or click <a
              class="primary-colour"
              style="font-weight:bold;"
              @click="getAccounts(0)"
            ><em>here</em></a> to load live accounts</span>.
          </div>
          <div v-if="accounts.length > 0" class="mb-3">
            <div style="float:right;margin-left:20px;">
              <div
                v-if="demoView"
                style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
                class="primary-colour align-items-center justify-content-center mr-1 d-flex d-md-flex mob-adjust"
                title="Show live accounts?"
                @click="getAccounts(0)"
              >
                <mdb-icon style="font-size:25px;margin-top:10px;" icon="toggle-off" />
              </div>
              <div
                v-else
                style="cursor:pointer;border-radius:50%;height:30px;width:30px;"
                class="primary-colour align-items-center justify-content-center mr-1 d-flex d-md-flex mob-adjust"
                title="Show demo accounts?"
                @click="getAccounts(1)"
              >
                <mdb-icon style="font-size:25px;margin-top:10px;" icon="toggle-on" />
              </div>
            </div>
            <ExpandableSearchBar
              :width="250"
              :max-widthpercentage="50"
              @search="filter = $event"
            />
          </div>

          <!--desktop-->
          <b-table
            v-if="accounts.length > 0 && mobileView === false"
            id="accounts-table"
            class="text-left tableTh"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="accounts"
            :per-page="accountsPerPage"
            :current-page="accountsCurrentPage"
            :fields="accountFields"
            :filter="filter"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(name)="data">
              <div style="padding-top:12px;margin-left:5px;" class="fiftyWide smFont">
                {{ data.item.Name }}
              </div>
            </template>
            <template #cell(accountid)="data">
              <div style="margin-top:12px;margin-left:5px;" class="smFont">
                {{ data.item.AccountId }}
              </div>
            </template>
            <template #cell(dateadded)="data">
              <div style="margin-top:12px;margin-left:5px;" class="smFont">
                {{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }}
              </div>
            </template>
            <template #cell(actionsa)="data">
              <div class="float-right webDisplay">
                <button
                  title="Edit this account?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;"
                  @click="editAccount(data.item)"
                >
                  <mdb-icon icon="pencil-alt" />
                </button>
                <button
                  v-if="data.item.AccountId !== userData.aid"
                  title="Switch to this account?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;"
                  @click="showSwitchAccountModal(data.item.AccountId)"
                >
                  <mdb-icon icon="exchange-alt" />
                </button>
              </div>
              <mdb-popover
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;float:right;"
                class="grey-text mobDisplay"
              >
                <div slot="body">
                  <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="editAccount(data.item)">
                    <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                  </h6>
                  <h6
                    v-if="data.item.AccountId !== userData.aid"
                    style="margin-left:-10px;padding:10px;"
                    @click="showSwitchAccountModal(data.item.AccountId)"
                  >
                    <a class="ml-1 grey-link"><mdb-icon icon="exchange-alt" /> Switch</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  title="More actions"
                  style="color:#555;font-size:1rem;transform:translateY(2px)"
                  class="p-2 ellipsisButtonHover mr-2"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </template>
          </b-table>

          <!--mobile-->
          <b-table
            v-if="accounts.length > 0 && mobileView === true"
            id="accounts-table"
            class="text-left tableTh"
            head-variant="dark"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="accounts"
            :per-page="accountsPerPage"
            :current-page="accountsCurrentPage"
            :fields="mobileAccountFields"
            :filter="filter"
            hover
            small
            sort-icon-left
            responsive="sm"
          >
            <template #cell(name)="data">
              <div style="padding-top:12px;margin-left:5px;" class="smFont">
                {{ data.item.Name }}
              </div>
            </template>
            <template #cell(accountid)="data">
              <div style="margin-top:10px;margin-left:5px;" class="smFont">
                {{ data.item.AccountId }}
              </div>
            </template>
            <template #cell(actionsa)="data">
              <div class="float-right webDisplay">
                <button
                  title="Edit this account?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;"
                  @click="editAccount(data.item)"
                >
                  <mdb-icon icon="pencil-alt" />
                </button>
                <button
                  v-if="data.item.AccountId !== userData.aid"
                  title="Switch to this account?"
                  type="button"
                  class="btn btn-primary btn-md"
                  style="padding: 0.5rem 0.6rem;margin:0.175rem;"
                  @click="showSwitchAccountModal(data.item.AccountId)"
                >
                  <mdb-icon icon="exchange-alt" />
                </button>
              </div>
              <mdb-popover
                trigger="click"
                :options="{placement: 'bottom'}"
                style="cursor:pointer;float:right;"
                class="grey-text mobDisplay"
              >
                <div slot="body">
                  <h6 style="margin-left:-10px;margin-top:-5px;padding:10px;" @click="editAccount(data.item)">
                    <a class="ml-1 grey-link"><i class="fa fa-pencil-alt" /> Edit</a>
                  </h6>
                  <h6
                    v-if="data.item.AccountId !== userData.aid"
                    style="margin-left:-10px;padding:10px;"
                    @click="showSwitchAccountModal(data.item.AccountId)"
                  >
                    <a class="ml-1 grey-link"><mdb-icon icon="exchange-alt" /> Switch</a>
                  </h6>
                </div>
                <mdb-icon
                  slot="reference"
                  title="More actions"
                  style="color:#555;font-size:1rem;transform:translateY(2px);margin-top: 8px;"
                  class="p-2 ellipsisButtonHover mr-2"
                  icon="ellipsis-v"
                />
              </mdb-popover>
            </template>
          </b-table>

          <b-pagination
            v-show="accounts.length > 5"
            v-model="accountsCurrentPage"
            class="float-right mt-4"
            :total-rows="accountsRows"
            :per-page="accountsPerPage"
            first-number
            last-number
            aria-controls="notifications-table"
            prev-text="<"
            next-text=">"
            style="margin-bottom:30px;"
          />
          <div
            v-show="mobileView === false"
            md="2"
            offset-md="10"
          >
            <button
              v-if="accounts.length > 0"
              style="float: left;margin-right: 0px;width: 110px;padding: 6px !important;text-align: center;margin-top:25px;"
              title="Add account"
              class="mdc-button btn"
              @click="addAccount()"
            >
              <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
            </button>
          </div>
          <div class="fixed bottom-0 mobDisplay" style="background: white;width: 100%;border-top: 1px solid rgb(161, 161, 161);z-index: 1001;margin-left: -33px;height: 55px;">
            <div class="row" style="margin-top:5px;">
              <div class="col-sm-12 text-center">
                <button
                  v-if="accounts.length > 0"
                  style="width: 110px;padding: 6px !important;text-align: center;"
                  title="Add account"
                  class="mdc-button btn-solid-outline-mob btn"
                  @click="addAccount()"
                >
                  <span class="secondary-colour"><i class="fa fa-plus-circle" /> Add</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--add/edit user form-->
        <div
          v-show="addEditAccountForm"
          style="text-align:left;padding:2px;margin-top:40px;overflow-y:auto;overflow-x:hidden;margin-bottom:100px;"
        >
          <div class="h5 grey-text" style="margin-bottom:20px;">
            <span v-show="edit">Edit Account: Mandatory Fields</span><span v-show="!edit">Add Account: Mandatory Fields</span>
          </div>
          <validation-observer v-slot="{ invalid, reset }">
            <form
              ref="form"
              @submit.prevent="saveAddEditAccount()"
              @reset.prevent="reset"
            >
              <div
                v-show="edit"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-info prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Account Guid"
                ><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
                <mdb-input
                  v-model="acc.Id"
                  type="text"
                  disabled
                  label="Account Id"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedA === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(1, acc.Id)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
              <div
                v-show="edit"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Guid"
                ><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
                <mdb-input
                  v-model="acc.Guid"
                  type="text"
                  disabled
                  label="Guid"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedB === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(2, acc.Guid)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i
                  class="fas fa-building prefix"
                  style="margin-top:4px;margin-left: -15px;cursor:help;"
                  title="Company Name"
                ><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Company name"
                  rules="required"
                >
                  <mdb-input
                    v-model="acc.Name"
                    type="text"
                    auto-complete="company-name"
                    label="Company Name"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    You must enter a company name
                  </div>
                </validation-provider>
              </div>
              <div
                v-show="edit || userData.iss"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-building prefix"
                  style="margin-top:4px;margin-left: -15px;cursor:help;"
                  title="Url friendly name"
                ><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="URL Friendly Name"
                  rules="required|url-friendly-company-name"
                >
                  <mdb-input
                    v-model="acc.URLFriendlyName"
                    type="text"
                    auto-complete="url-friendly-company-name"
                    label="URL Friendly Company Name"
                    style="margin-left:33px;"
                    size="sm"
                    maxlength="11"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    You must enter a URL friendly company name - lower case alphanumeric only
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;margin-top:-10px;">
                <i
                  class="fas fa-globe-africa prefix"
                  style="margin-top:4px;margin-left: -15px;cursor:help;"
                  title="Country"
                ><div style="color:red;margin-left:25px;margin-top:-30px;">*</div></i>
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Country"
                  rules="required|country-name"
                >
                  <mdb-select
                    v-model="countries"
                    placeholder="Country"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div style="font-size: 0.7rem;margin-left: 33px;margin-top:0px;margin-bottom:0px;">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i
                  class="fas fa-envelope prefix"
                  style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;cursor:help;"
                  title="Support email"
                ><div style="color:red;margin-left:25px;margin-top:-25px;">*</div></i>
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Support Email Contact"
                  rules="required"
                >
                  <mdb-input
                    v-model="acc.SupportEmailContact"
                    type="email"
                    auto-complete="ename"
                    label="Support Email Contact"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    You must enter a support email address
                  </div>
                </validation-provider>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i
                  class="fas fa-phone prefix"
                  style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;cursor:help;"
                  title="Support telephone"
                ><div style="color:red;margin-left:25px;margin-top:-25px;">*</div></i>
                <validation-provider
                  v-slot="{ errors }"
                  mode="lazy"
                  name="Support Telephone"
                  rules="required"
                >
                  <mdb-input
                    v-model="acc.SupportTelephone"
                    type="text"
                    auto-complete="tname"
                    label="Support Telephone"
                    style="margin-left:33px;"
                    size="sm"
                  />
                  <div v-if="errors[0]" style="font-size: 0.7rem;margin-left: 33px;margin-top:-10px;margin-bottom:0px;">
                    You must enter a support telephone number
                  </div>
                </validation-provider>
              </div>
              <!--while there is both a TxtReminder and TxtInvites field on the account this toggle is a single switch that sets both fields -->
              
              <div class="md-form form-sm" style="margin-left:15px;">
                <i
                  class="fas fa-users prefix"
                  style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;cursor:help;"
                  title="Number of seats"
                ><div style="color:red;margin-left:30px;margin-top:-25px;">*</div></i>
                <mdb-input
                  v-model="usersCount"
                  :min="1"
                  :max="50"
                  type="number"
                  style="margin-left: 33px;margin-top:40px;"
                  value="1"
                  label="Seats"
                  outline
                />
              </div>
              
              <div class="md-form form-sm" style="margin-left:15px;">
                <i
                  class="fas fa-info prefix"
                  style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;cursor:help;"
                  title="Retention"
                ><div style="color:red;margin-left:30px;margin-top:-25px;">*</div></i>
                <mdb-input
                  v-model="acc.Retention"
                  :min="1"
                  :max="60"
                  type="number"
                  style="margin-left: 33px;margin-top:40px;"
                  value="1"
                  label="Months to retain data"
                  outline
                />
              </div>
              <div class="h5 grey-text" style="margin-bottom:20px;">
                <span v-show="edit">Edit Account: Optional Fields</span><span v-show="!edit">Add Account: Optional Fields</span>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i
                  class="fas fa-sitemap prefix"
                  style="margin-top:4px;margin-left: -15px;cursor:help;"
                  title="Master account group"
                />
                <mdb-select
                  v-if="!accountLocked"
                  v-model="mags"
                  placeholder="Master Account Group"
                  style="margin-left:33px;"
                  size="sm"
                />
                <mdb-select
                  v-else
                  v-model="mags"
                  style="margin-left:33px;"
                  size="sm"
                  disabled
                />
              </div>
              <div
                class="md-form form-sm"
                style="padding-left: 15px;margin-bottom:40px;"
                @click="toggleDemo()"
              >
                <i
                  class="fas fa-info prefix"
                  style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                  title="Demo or trial account"
                />
                <mdb-input
                  v-model="acc.IsTrial"
                  type="checkbox"
                  auto-complete="demo"
                  label="Demo Account?"
                  style="margin-left:16px;"
                  size="sm"
                />
              </div>
              <div
                v-show="edit"
                v-if="acc.Id !== userData.aid"
                class="md-form form-sm"
                style="padding-left: 15px;margin-bottom:40px;"
                @click="toggleDelete()"
              >
                <i
                  class="fas fa-trash prefix"
                  style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                  title="Delete account"
                />
                <mdb-input
                  v-model="acc.IsDeleted"
                  type="checkbox"
                  auto-complete="delt"
                  label="Delete Account?"
                  style="margin-left:16px;"
                  size="sm"
                />
              </div>
              <div
                class="md-form form-sm"
                style="padding-left: 15px;margin-bottom:40px;"
                @click="toggleJobTrain()"
              >
                <i
                  class="fas fa-info prefix"
                  style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                  title="Jobtrain account?"
                />
                <mdb-input
                  v-model="acc.Jobtrain"
                  type="checkbox"
                  auto-complete="demo"
                  label="Jobtrain account?"
                  style="margin-left:16px;"
                  size="sm"
                />
              </div>

              <div class="h5 grey-text" style="margin-bottom:20px;">
                <span>Email Management</span>
              </div>
              <div
                class="md-form form-sm"
                style="padding-left: 15px;margin-bottom:40px;"
                @click="toggleSupressEmails()"
              >
                <i
                  class="fas fa-ban prefix"
                  style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                  title="Supress Emails"
                  @click="toggleSupressEmails()"
                />
                <mdb-input
                  v-model="acc.SupressEmails"
                  type="checkbox"
                  auto-complete="supr"
                  label="Supress ALL emails?"
                  style="margin-left:16px;"
                  size="sm"
                />
              </div>

              <div v-if="!acc.SupressEmails">
                <div
                  class="md-form form-sm"
                  style="padding-left: 15px;margin-bottom:40px;display:none;"
                  @click="toggleSMS()"
                >
                  <i
                    class="fas fa-sms prefix"
                    style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                    title="SMS"
                    @click="toggleSMS()"
                  />
                  <mdb-input
                    v-model="acc.TxtInvites"
                    type="checkbox"
                    auto-complete="delt"
                    label="Reminders?"
                    style="margin-left:16px;"
                    size="sm"
                  />
                </div>
                <div
                  v-if="acc.TxtInvites"
                  class="md-form form-sm"
                  style="padding-left: 15px;display:none;"
                >
                  <i
                    class="fas fa-pen prefix"
                    style="margin-top:4px;margin-left: -15px;cursor:help;"
                    title="SMS display name (11 characters max)"
                  />
                  <mdb-input
                    v-model="acc.SMSFriendlyName"
                    type="text"
                    auto-complete="url-friendly-company-name"
                    label="SMS Display Name (11 characters max)"
                    style="margin-left:33px;"
                    size="sm"
                    maxlength="11"
                  />
                </div>

                <!--this will means public sharing links will appear in system completion emails-->
                <div
                  class="md-form form-sm"
                  style="padding-left: 15px;margin-bottom:40px;"
                  @click="togglePublicLinks()"
                >
                  <i
                    class="fas fa-info prefix"
                    style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                    title="Public links enabled"
                  />
                  <mdb-input
                    v-model="acc.PublicLinks"
                    type="checkbox"
                    auto-complete="publ"
                    label="Enable one-way public links and add to completion emails?"
                    style="margin-left:16px;"
                    size="sm"
                  />
                </div>
                <div
                  class="md-form form-sm"
                  style="padding-left: 15px;margin-bottom:40px;"
                >
                  <i
                    class="fas fa-mail-bulk prefix"
                    style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                    title="Catch All Email (send notification emails to a 'shared' mailbox)"
                  />
                  <mdb-input
                    v-model="acc.CatchAllEmail"
                    type="text"
                    auto-complete="repr"
                    label="Catch All Email (separate additional emails with a semi-colon)"
                    style="margin-left:33px;"
                    size="sm"
                  />
                </div>
              

                <!--
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i class="fas fa-info prefix" style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="acc.HostedPageId"
                  type="text"
                  auto-complete="jname"
                  label="Hosted Page Id"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i class="fas fa-info prefix" style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;" />
                <mdb-input
                  v-model="acc.CustomerId"
                  type="text"
                  auto-complete="pname"
                  label="Customer Id"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              -->

                <div               
                  class="md-form form-sm"
                  style="padding-left: 15px;margin-bottom:40px;margin-top:-20px;"
                  @click="toggleReporting()"
                >
                  <i
                    class="fas fa-chart-line prefix"
                    style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                    title="Automated summary report"
                  />
                  <mdb-input
                    v-model="acc.AutomatedReporting"
                    type="checkbox"
                    auto-complete="autr"
                    label="Automated report summary?"
                    style="margin-left:16px;"
                    size="sm"
                  />
                </div>
                <div
                  v-if="acc.AutomatedReporting"
                  class="md-form form-sm"
                  style="padding-left: 15px;"
                >
                  <i
                    class="fas fa-clock prefix"
                    style="margin-top:4px;margin-left: -15px;cursor:help;"
                    title="Report frequency"
                  />
                  <validation-provider
                    v-slot="{ errors }"
                    mode="lazy"
                    name="Frq"
                    rules="required|report-frequency"
                  >
                    <mdb-select
                      v-model="freq"
                      placeholder="Report frequency"
                      style="margin-left:33px;"
                      size="sm"
                    />
                    <div style="font-size: 0.7rem;margin-left: 33px;margin-top:0px;margin-bottom:0px;">
                      {{ errors[0] }}
                    </div>
                  </validation-provider>
                </div>
                <div
                  v-if="acc.AutomatedReporting"
                  class="md-form form-sm"
                  style="padding-left: 15px;"
                >
                  <i
                    class="fas fa-users prefix"
                    style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;"
                    title="Report recipients"
                  />
                  <mdb-input
                    v-model="acc.ReportRecipient"
                    type="text"
                    auto-complete="repr"
                    label="Report recipients (separate additional emails with a semi-colon)"
                    style="margin-left:33px;"
                    size="sm"
                  />
                </div>
              </div>

              <div class="h5 grey-text" style="margin-bottom:20px;">
                <span v-show="edit">Edit Account: Features</span><span v-show="!edit">Add Account: Features</span>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;margin-bottom:20px;">
                <i
                  class="fas fa-list prefix"
                  style="margin-left: -15px;margin-top: 9px;font-size: 1.3rem;cursor:help;"
                  title="Account features to enable/disable"
                />
                <span @click="toggleCandidates()"><mdb-input
                  v-model="acc.Candidates"
                  type="checkbox"
                  auto-complete="nrl"
                  label="Candidates Enabled?"
                  style="margin-left:16px;font-weight:bold;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleRoles()"><mdb-input
                  v-model="acc.Roles"
                  type="checkbox"
                  auto-complete="nrl"
                  label="Roles Enabled? "
                  style="margin-left:16px;font-weight:bold;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleMeeting()"><mdb-input
                  v-model="acc.Meeting"
                  type="checkbox"
                  auto-complete="mrl"
                  label="Live Enabled? "
                  style="margin-left:16px;font-weight:bold;"
                  size="sm"
                /></span>
                <!--VCVX
                <span style="padding:1px;" @click="toggleIntros()"><mdb-input
                  v-model="acc.Intros"
                  type="checkbox"
                  auto-complete="nin"
                  label="Intros Enabled?"
                  style="margin-left:16px;font-weight:bold;"
                  size="sm"
                /></span>-->
                <span style="padding:1px;" @click="toggleEvents()"><mdb-input
                  v-model="acc.Events"
                  type="checkbox"
                  auto-complete="eve"
                  label="Events Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleVideoLibrary()"><mdb-input
                  v-model="acc.VideoLibrary"
                  type="checkbox"
                  auto-complete="nvl"
                  label="Video Library Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleMeetNow()"><mdb-input
                  v-model="acc.MeetNow"
                  type="checkbox"
                  auto-complete="nmn"
                  label="Meet Now Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <!--<span style="padding:1px;" @click="toggleClientPortal()"><mdb-input
                  v-model="acc.ClientPortal"
                  type="checkbox"
                  auto-complete="ncp"
                  label="Client Portal Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>-->
                <span style="padding:1px;" @click="toggleReports()"><mdb-input
                  v-model="acc.Reports"
                  type="checkbox"
                  auto-complete="nrp"
                  label="Reports Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleNotifications()"><mdb-input
                  v-model="acc.Notification"
                  type="checkbox"
                  auto-complete="nnt"
                  label="Notifications Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <span style="padding:1px;" @click="toggleScoring()"><mdb-input
                  v-model="acc.Scoring"
                  type="checkbox"
                  auto-complete="nsc"
                  label="Scoring Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <span 
                  v-if="acc.Roles || acc.Meeting"
                  style="padding:1px;"
                  @click="toggleTranscriptions()"
                ><mdb-input
                  v-model="acc.Transcriptions"
                  type="checkbox"
                  auto-complete="trn"
                  label="Transcriptions Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>
                <!--<span style="padding:1px;" @click="toggleSurveys()"><mdb-input
                  v-model="acc.Surveys"
                  type="checkbox"
                  auto-complete="nsv"
                  label="Surveys Enabled?"
                  style="margin-left:16px;"
                  size="sm"
                /></span>-->
              </div>
              <div class="h5 grey-text" style="margin-bottom:20px;">
                <span v-show="edit">Edit Account: Public API Settings</span><span v-show="!edit">Add Account: Public API Settings</span>
              </div>
              <div
                v-if="acc.ApplicationId == null"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application reference for bearer token"
                />
                <mdb-input
                  v-model="acc.ApplicationId"
                  type="text"
                  label="Application Id (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div
                v-else
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application reference for bearer token"
                />
                <mdb-input
                  v-model="acc.ApplicationId"
                  disabled
                  type="text"
                  label="Application Id (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedC === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(3, acc.ApplicationId)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
              <div
                v-if="acc.ApplicationSecret == null"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application secret for bearer token"
                />
                <mdb-input
                  v-model="acc.ApplicationSecret"
                  type="text"
                  label="Application Secret (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div
                v-else
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application secret for bearer token"
                />
                <mdb-input
                  v-model="fakeSecret"
                  disabled
                  type="text"
                  label="Application Secret (required for bearer token)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              <div
                v-if="acc.ClientId"
                v-show="edit"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application client reference"
                />
                <mdb-input
                  v-model="acc.ClientId"
                  type="text"
                  disabled
                  label="Client Id (mandatory for all API calls)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedD === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(4, acc.ClientId)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>
              <div
                v-if="acc.ClientId"
                v-show="edit"
                class="md-form form-sm"
                style="padding-left: 15px;"
              >
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top:4px;font-size: 1.3rem;cursor:help;"
                  title="Application email reference"
                />
                <mdb-input
                  v-model="acc.Id"
                  type="text"
                  disabled
                  label="Account Id (mandatory for all API calls)"
                  style="margin-left:33px;"
                  size="sm"
                />
                <div style="float:right;margin-right: 20px;">
                  <i
                    v-if="copiedE === false"
                    class="fas fa-copy prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:pointer;"
                    title="Copy to clipboard!"
                    @click="copyToClipboard(5, acc.Id)"
                  />
                  <i
                    v-else
                    class="fas fa-check prefix copyTop"
                    style="margin-top:10px;font-size: 0.8rem;cursor:none;"
                  />
                </div>
              </div>

              <div
                v-if="acc.Jobtrain"
                class="h5 grey-text"
                style="margin-bottom:20px;"
              >
                <span v-show="edit">Edit Account: Jobtrain Settings</span><span v-show="!edit">Add Account: Jobtrain Settings</span>
              </div>
              <div class="md-form form-sm" style="padding-left: 15px;">
                <i
                  class="fas fa-tools prefix"
                  style="margin-left: -15px;margin-top: 4px;font-size: 1.3rem;cursor:help;"
                  title="Custom end point URL"
                />
                <mdb-input
                  v-model="acc.CustomJobTrainDomain"
                  type="text"
                  auto-complete="custe"
                  label="Custom end point URL (please supply full 'https://' URL)"
                  style="margin-left:33px;"
                  size="sm"
                />
              </div>
              
              <div class="md-form form-sm" style="margin-left: -15px;padding-left: 15px;color:red;">
                <div style="font-size:2rem;margin-bottom: -43px;">
                  *
                </div><div style="margin-left:20px;font-size:14px;">
                  required fields.
                </div>
              </div>
              <mdb-btn
                type="reset"
                class="float-left btn-outline"
                size="sm"
                style="width:100px;"
                @click="cancelAddEditAccountForm()"
              >
                CANCEL
              </mdb-btn>
              <mdb-btn
                v-show="!buttonClick"
                :disabled="invalid || usersCount < 1 && (acc.Roles === false && acc.Candidates === false && acc.Intros === false)"
                type="submit"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                <span v-show="edit">SAVE</span><span v-show="!edit">ADD</span>
              </mdb-btn>
              <mdb-btn
                v-show="buttonClick"
                class="float-right"
                size="sm"
                style="width:100px;"
              >
                <mdb-icon icon="fa fa-pulse fa-spinner" />
              </mdb-btn>
            </form>
          </validation-observer>
        </div>

        <!--switch accounts modal-->
        <mdb-modal
          id="alertModal"
          :show="switchModal"
          @close="switchModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>SWITCH ACCOUNTS</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body :class="{ 'modalHeight': mobileView }">
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              Are you sure you want to switch to this account?
            </p>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn
              v-show="!buttonClick"
              color="primary"
              size="sm"
              style="width:100px;"
              @click.native="switchAccount"
            >
              SWITCH
            </mdb-btn>
            <mdb-btn
              v-show="buttonClick"
              class="float-right"
              size="sm"
              style="width:100px;"
            >
              <mdb-icon icon="fa fa-pulse fa-spinner" />
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>

        <!--error modal-->
        <mdb-modal
          id="errorModal"
          :show="errorModal"
          @close="errorModal = false"
        >
          <mdb-modal-header>
            <mdb-modal-title>{{ errorModalTitle.toUpperCase() }}</mdb-modal-title>
          </mdb-modal-header>
          <mdb-modal-body :class="{ 'modalHeight': mobileView }">
            <p class="my-4 grey-text" style="font-size:0.8rem;text-align:left;">
              <span v-html="error" />
            </p>
          </mdb-modal-body>
          <mdb-modal-footer>
            <mdb-btn
              color="primary"
              size="sm"
              style="width:100px;"
              @click.native="closeErrorModal"
            >
              CLOSE
            </mdb-btn>
          </mdb-modal-footer>
        </mdb-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserData, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/composables/useMobile'
import { ValidationProvider, extend } from 'vee-validate'
import logoutService from '@/api/services/logout.service'
import adminService from '@/api/services/admin.service'
import signUpService from '@/api/services/signup.service'
import { logout } from '@/methods/auth.js'
import { userLanguage } from '@/common/userLanguage'

import { mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModal, mdbBtn } from 'mdbvue'
import ExpandableSearchBar from '@/components/UI/expandableSearch.vue'

extend('company-name', {
  validate: company => { return company.length > 0 },
  lazy: true,
  message: 'Enter a valid company name'
})

extend('url-friendly-company-name', {
  getMessage () {
    return 'Enter a valid URL friendly company name'
  },
  validate (value) {
    return !value.match(/[^-\]_@&+[A-z0-9]/)
  }
})
extend('country-name', {
  validate: country => { return country.length > 0 },
  lazy: true,
  message: 'Enter a valid country'
})
extend('report-frequency', {
  validate: frq => { return frq.length > 0 },
  lazy: true,
  message: 'Please select a report frequency'
})
extend('customer-id', {
  validate: customerid => { return customerid.length > 0 },
  lazy: true,
  message: 'Enter a valid customer id'
})

export default {
  name: 'Admin',
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbBtn,
    ExpandableSearchBar,
    ValidationProvider
  },
  setup () {
    const userStore = useUserData()
    const { authData, userData, leftColumnActive } = storeToRefs(userStore)
    const { mobileView } = useMobile()
    const candidateStore = useCandidate()
    const {
      candidateTableRecentSearches
    } = storeToRefs(candidateStore)

    return {
      authData,
      userData,
      leftColumnActive,
      mobileView,
      candidateTableRecentSearches
    }
  },
  data () {
    return {
      accountFields: [
        { key: 'Name', label: '  Name', tdClass: 'headerPaddingUsers col40 sortIcon', sortable: true },
        { key: 'AccountId', label: '  Account Id', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'DateAdded', label: '  Added', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'ActionsA', label: '', tdClass: 'headerPaddingUsers col20', sortable: false }
      ],
      mobileAccountFields: [
        { key: 'Name', label: '  Name', tdClass: 'headerPaddingUsers col70 sortIcon', sortable: true },
        { key: 'AccountId', label: '  Id', tdClass: 'headerPaddingUsers col20 sortIcon', sortable: true },
        { key: 'ActionsA', label: '', tdClass: 'headerPaddingUsers col10', sortable: false }
      ],
      logFields: [
        { key: 'Success', label: ' ?  ', tdClass: 'headerPadding col15 sortIcon', sortable: true },
        { key: 'Section', label: '  Section', tdClass: 'headerPadding col45 sortIcon', sortable: true },
        { key: 'DateAdded', label: '  Date', tdClass: 'headerPadding col30 sortIcon', sortable: true },
        { key: 'Actions', label: '', tdClass: 'headerPadding col10', sortable: false }
      ],
      countries: [
        { text: 'United Kingdom', value: 'GB', selected: true },
        { text: 'Ireland', value: 'IE' },
        { text: 'United States', value: 'US' },
        { text: 'Canada', value: 'CA' }
      ],
      freq: [
        { text: 'Please select report frequency', value: 0, selected: true },
        { text: 'Monday', value: 1 },
        { text: 'Tuesday', value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 },
        { text: 'Saturday', value: 6 },
        { text: 'Sunday', value: 7 },
        { text: 'Weekly', value: 8 },
        { text: 'Monthly', value: 9 },
      ],
      mags: [],
      switchModal: false,
      showSwitching: false,
      accounts: [],
      acc: {
        Id: 0,
        Name: '',
        URLFriendlyName: '',
        Country: '',
        Seats: '',
        HostedPageId: '',
        CustomerId: '',
        IsTrial: false,
        SupportEmailContact: '',
        SupportTelephone: '',
        IsDeleted: false,
        Retention: 12,
        Roles: true,
        VideoLibrary: true,
        Intros: true,
        MeetNow: false,
        Events: false,
        ClientPortal: true,
        Reports: true,
        Notification: true,
        Scoring: true,
        Surveys: true,
        Candidates: true,
        PublicLinks: false,
        AccountGroupMaster: 0,
        AutomatedReporting: false,
        ReportRecipient: '',
        ReportFrequency: 0,
        CatchAllEmail: '',
        SupressEmails: false,
        SMSFriendlyName: '',
        Jobtrain: false,
        CustomJobTrainDomain: '',
        Meeting: true,
        Transcriptions: false
      },
      accountsLoaded: false,
      sortBy: '',
      sortDesc: false,
      accountsPerPage: 8,
      accountsCurrentPage: 1,
      filter: null,
      buttonClick: false,
      userLanguage,
      filterOn: [],
      errorModal: false,
      errorModalTitle: '',
      error: '',
      accountId: null,
      usersCount: 25, //was zero but seats hidden as irrelevant now?
      edit: false,
      addEditAccountForm: false,
      copiedA: false,
      copiedB: false,
      copiedC: false,
      copiedD: false,
      copiedE: false,
      demoView: false,
      fakeSecret: '**********************************',
      accountLocked: false
    }
  },
  computed: {
    accountsRows () {
      return this.accounts.length
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    country: function () {
      var c = ''
      this.countries.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    frq: function () {
      var c = ''
      this.freq.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    selectedMag () {
      return this.mags.filter(page => page.selected)[0].index
    },
    mag: function () {
      var c = ''
      this.mags.forEach(element => {
        if (element.selected === true) {
          c = element.value
        }
      })
      return c
    },
    dateFormat: {
      get () {
        return this.$material.locale.dateFormat
      },
      set (val) {
        this.$material.locale.dateFormat = val
      }
    }
  },
  created () {
    this.$parent.accountsLoading = true
    this.getAccounts(0)
    this.getMasterAccounts(this.userData.aid)
  },
  methods: {
    logout,
    copyToClipboard (type, value) {
      navigator.clipboard.writeText(value)
      if (type === 1) {
        this.copiedA = true
        setTimeout(() => { this.copiedA = false }, 1500)
      } else if (type === 2) {
        this.copiedB = true
        setTimeout(() => { this.copiedB = false }, 1500)
      } else if (type === 3) {
        this.copiedC = true
        setTimeout(() => { this.copiedC = false }, 1500)
      } else if (type === 4) {
        this.copiedD = true
        setTimeout(() => { this.copiedD = false }, 1500)
      } else {
        this.copiedE = true
        setTimeout(() => { this.copiedE = false }, 1500)
      }
    },
    togglePublicLinks () {
      if (this.acc.PublicLinks === true) {
        this.acc.PublicLinks = false
      } else {
        this.acc.PublicLinks = true
      }
    },
    toggleDemo () {
      if (this.acc.IsTrial === true) {
        this.acc.IsTrial = false
      } else {
        this.acc.IsTrial = true
      }
    },
    toggleJobTrain () {
      if (this.acc.Jobtrain === true) {
        this.acc.Jobtrain = false
      } else {
        this.acc.Jobtrain = true
      }
    },
    toggleTranscriptions () {
      if (this.acc.Transcriptions === true) {
        this.acc.Transcriptions = false
      } else {
        this.acc.Transcriptions = true
      }
    },
    toggleSupressEmails () {
      this.acc = { ...this.acc, SupressEmails: !this.acc.SupressEmails }
      this.$forceUpdate()
    },
    toggleSMS () {
      this.acc = { ...this.acc, TxtInvites: !this.acc.TxtInvites }
      this.$forceUpdate()
    },
    toggleReporting (){
      if (this.acc.AutomatedReporting === true) {
        this.acc.AutomatedReporting = false
      } else {
        this.acc.AutomatedReporting = true
      }
    },
    toggleRoles () {
      if (this.acc.Roles === true) {
        this.acc.Roles = false
        this.acc.Reports = false
        this.acc.Scoring = false
        this.acc.Surveys = false
      } else {
        this.acc.Roles = true
        this.acc.Reports = true
        this.acc.Candidates = true
      }
    },
    toggleMeeting () {
      if (this.acc.Meeting === true) {
        this.acc.Meeting = false
      } else {
        this.acc.Meeting = true
      }
    },
    toggleCandidates () {
      if (this.acc.Candidates === true) {
        this.acc.Candidates = false
        this.acc.Roles = false
        this.acc.Meeting = false
        this.acc.Reports = false
        this.acc.Scoring = false
        this.acc.Surveys = false
        this.acc.ClientPortal = false
        this.acc.MeetNow = false
      } else {
        this.acc.Candidates = true
        this.acc.Roles = true
        this.acc.Meeting = true
        this.acc.Reports = true
        this.acc.ClientPortal = true
        this.acc.MeetNow = true
      }
    },
    toggleVideoLibrary () {
      if (this.acc.VideoLibrary === true) {
        this.acc.VideoLibrary = false
      } else {
        this.acc.VideoLibrary = true
      }
    },
    toggleIntros () {
      if (this.acc.Intros === true) {
        this.acc.Intros = false
      } else {
        this.acc.Intros = true
      }
    },
    toggleMeetNow () {
      if (this.acc.MeetNow === true) {
        this.acc.MeetNow = false
      } else {
        this.acc.MeetNow = true
      }
    },
    toggleClientPortal () {
      if (this.acc.ClientPortal === true) {
        this.acc.ClientPortal = false
      } else {
        this.acc.ClientPortal = true
      }
    },
    toggleReports () {
      if (this.acc.Reports === true) {
        this.acc.Reports = false
      } else {
        this.acc.Reports = true
      }
    },
    toggleEvents () {
      if (this.acc.Events === true) {
        this.acc.Events = false
      } else {
        this.acc.Events = true
      }
    },
    toggleNotifications () {
      if (this.acc.Notification === true) {
        this.acc.Notification = false
      } else {
        this.acc.Notification = true
      }
    },
    toggleScoring () {
      if (this.acc.Scoring === true) {
        this.acc.Scoring = false
      } else {
        this.acc.Scoring = true
      }
    },
    toggleSurveys () {
      if (this.acc.Surveys === true) {
        this.acc.Surveys = false
      } else {
        this.acc.Surveys = true
      }
    },
    toggleDelete () {
      if (this.acc.IsDeleted === true) {
        this.acc.IsDeleted = false
      } else {
        this.acc.IsDeleted = true
        this.errorModal = true
        this.errorModalTitle = 'Deletion Warning!'
        this.error = 'Continiung will delete this account!'
      }
    },
    getAccounts (type) {
      this.$parent.accountsLoading = true
      if (type === 1) {
        this.demoView = true
      } else {
        this.demoView = false
      }
      adminService.getAccounts(type).then((response) => {
        this.accounts = response.data
        setTimeout(() => {
          this.$parent.accountsLoading = false
          this.accountsLoaded = true
        }, 3000)
      }).catch((error) => {
        setTimeout(() => {
          this.$parent.accountsLoading = false
          this.accountsLoaded = true
        }, 3000)
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    showSwitchAccountModal (accountId) {
      this.accountId = accountId
      this.switchModal = true
    },
    switchAccount () {
      this.buttonClick = true
      adminService.switchAccount(this.accountId).then((response) => {
        this.userData = response.data
        this.switchModal = false
        if (this.demoView) {
          this.getAccounts(1)
        } else {
          this.getAccounts(0)
        }
        this.candidateTableRecentSearches = []
        this.buttonClick = false
      }).catch((error) => {
        this.switchModal = false
        this.accountId = null
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    errorAlert (error, err) {
      try {
        if (error.response.data != null) {
          if (error.response.data.Message != null) {
            this.errorModal = true
            this.error = error.response.data.Message
            if (this.error === '') { this.error = err }
          } else if (error.response.data.error_description != null) {
            this.errorModal = true
            this.error = error.response.data.error_description
            if (this.error === '') { this.error = err }
          } else {
            this.errorModal = true
            this.error = error.response.data
            if (this.error === '') { this.error = err }
          }
        } else {
          this.errorModal = true
          this.error = err
        }
        if (error.response.data.Message === 'Authorization has been denied for this request.') {
          this.logOut()
        }
      } catch {
        this.errorModal = true
        this.error = err
      }
    },
    logOut () {
      logoutService.logout().then(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      }).catch(() => {
        this.logout().then(() => this.$router.push('/').catch(() => { }))
      })
    },
    getMasterAccounts (accountId) {
      adminService.getMasterAccountGroups(accountId).then((response) => {
        this.mags.push({ text: 'Master Account Group', value: 0, selected: true })
        response.data.forEach(o => {
          if (o.Locked && accountId > 0) {
            this.accountLocked = true
          }
          this.mags.push({ text: o.AccountGroupName, value: o.AccountGroupMasterId })
        })
        if (this.edit) {
          if (this.accountLocked) {
            this.mags = []
            response.data.forEach(o => {
              if (o.AccountGroupMasterId == this.acc.AccountGroupMaster) {
                this.mags.push({ text: o.AccountGroupName, value: o.AccountGroupMasterId, selected: true })
              }
            })
          }
        }
      }).catch(() => {
        //?
      })
    },
    addAccount () {
      this.addEditAccountForm = true
      this.accountLocked = false
      this.getMasterAccounts(0)
    },
    saveAddEditAccount () {
      this.buttonClick = true
      var dt = new Date(Date.now())
      this.acc.Oset = dt.getTimezoneOffset()
      this.acc.Tzone = Intl.DateTimeFormat().resolvedOptions().timeZone
      this.acc.Country = this.country
      this.acc.ReportFrequency = this.frq
      this.acc.Seats = this.usersCount
      this.acc.AccountGroupMaster = this.mag
      adminService.addEditAccount(this.acc).then(() => {
        this.buttonClick = false
        if (this.userData.aid === this.acc.Id) {
          try {
            signUpService.getUserDetails(this.authData.access_token).then(async (res) => {
              this.userData = res.data
              this.leftColumnActive = false
            })
          } catch { }
          this.userData.nos = this.acc.Seats
        }
        this.acc = {
          Id: 0,
          Name: '',
          URLFriendlyName: '',
          Country: '',
          Seats: '',
          HostedPageId: '',
          CustomerId: '',
          IsTrial: false,
          SupportEmailContact: '',
          SupportTelephone: '',
          IsDeleted: false,
          TxtInvites: false,
          Roles: true,
          VideoLibrary: true,
          Intros: true,
          MeetNow: false,
          ClientPortal: true,
          Reports: true,
          Events: false,
          Notification: true,
          Scoring: false,
          Surveys: false,
          Candidates: true,
          PublicLinks: false,
          AccountGroupMaster: 0,
          AutomatedReporting: false,
          ReportRecipient: '',
          ReportFrequency: 0,
          CatchAllEmail: '',
          SupressEmails: false,
          SMSFriendlyName: '',
          Jobtrain: false,
          CustomJobTrainDomain: '',
          Meeting: true,
          Transcriptions: false
        }
        this.usersCount = 0
        this.addEditAccountForm = false
        this.edit = false
        this.reset()
        this.accountsLoaded = false
        if (this.demoView) {
          this.getAccounts(1)
        } else {
          this.getAccounts(0)
        }
      }).catch((error) => {
        this.buttonClick = false
        this.errorModalTitle = 'Error!'
        this.errorAlert(error, 'Sorry an error has occurred!')
      })
    },
    editAccount (account) {

      if (account.AccountGroupMaster > 0) {
        this.accountLocked = true
      } else {
        this.accountLocked = false
      }

      this.edit = true
      this.addEditAccountForm = true
      this.acc.Id = account.AccountId
      this.acc.Name = account.Name
      this.acc.URLFriendlyName = account.URLFriendlyName
      this.acc.CustomerId = account.CustomerId
      this.acc.HostedPageId = account.HostedPageId
      this.acc.Guid = account.AccountGuid
      this.usersCount = account.NumberOfSeats
      this.acc.Retention = account.Retention
      this.acc.SupportEmailContact = account.SupportEmailContact
      this.acc.SupportTelephone = account.SupportTelephone
      this.acc.IsTrial = account.IsTrial
      this.acc.IsDeleted = account.IsDeleted
      this.acc.ClientId = account.ClientId
      this.acc.ClientSecret = account.ClientSecret
      this.acc.ApplicationId = account.ApplicationId
      this.acc.ApplicationSecret = account.ApplicationSecret
      this.acc.TxtInvites = account.TxtInvites
      this.acc.ApplicationEmailAddress = account.ApplicationEmailAddress
      this.acc.PublicLinks = account.PublicLinks
      this.acc.AccountGroupMaster = account.AccountGroupMaster
      this.acc.AutomatedReporting = account.AutomatedReporting
      this.acc.ReportRecipient = account.ReportRecipient
      this.acc.CatchAllEmail = account.CatchAllEmail
      this.acc.SupressEmails = account.SupressEmails
      this.acc.SMSFriendlyName = account.SMSFriendlyName
      this.acc.Transcriptions = account.Transcriptions
      this.mags = this.mags.map(q => q.value === this.acc.AccountGroupMaster ? { ...q, selected: true } : { ...q, selected: false })
      if (account.Country === 'IE') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE', selected: true },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA' }
        ]
      } else if (account.Country === 'US') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US', selected: true },
          { text: 'Canada', value: 'CA' }
        ]
      } else if (account.Country === 'CA') {
        this.countries = [
          { text: 'United Kingdom', value: 'GB' },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA', selected: true }
        ]
      } else {
        this.countries = [
          { text: 'United Kingdom', value: 'GB', selected: true },
          { text: 'Ireland', value: 'IE' },
          { text: 'United States', value: 'US' },
          { text: 'Canada', value: 'CA' }
        ]
      }
      if (account.ReportFrequency == 1) {
        this.freq = [
          { text: 'Monday', value: 1, selected: true },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 2) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2, selected: true },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 3) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3, selected: true },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 4) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4, selected: true },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 5) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5, selected: true },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 6) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6, selected: true },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 7) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7, selected: true },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 8) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8, selected: true },
          { text: 'Monthly', value: 9 },
        ]
      } else if (account.ReportFrequency == 9) {
        this.freq = [
          { text: 'Monday', value: 1 },
          { text: 'Tuesday', value: 2 },
          { text: 'Wednesday', value: 3 },
          { text: 'Thursday', value: 4 },
          { text: 'Friday', value: 5 },
          { text: 'Saturday', value: 6 },
          { text: 'Sunday', value: 7 },
          { text: 'Weekly', value: 8 },
          { text: 'Monthly', value: 9, selected: true },
        ]
      }
      this.acc.Roles = account.Roles
      this.acc.Meeting = account.Meeting
      this.acc.Reports = account.Reports
      this.acc.Scoring = account.Scoring
      this.acc.Surveys = account.Surveys
      this.acc.ClientPortal = account.ClientPortal
      this.acc.Candidates = account.Candidates
      this.acc.MeetNow = account.MeetNow
      this.acc.Notification = account.Notification
      this.acc.Events = account.Events
      this.acc.Intros = account.Intros
      this.acc.VideoLibrary = account.VideoLibrary
      this.acc.Jobtrain = account.Jobtrain
      this.acc.CustomJobTrainDomain = account.CustomJobTrainDomain
      this.getMasterAccounts(account.AccountId)
    },
    reset () {
      this.$refs.form.reset()
    },
    cancelAddEditAccountForm () {
      this.edit = false
      this.acc = {
        Id: 0,
        Name: '',
        URLFriendlyName: '',
        Country: '',
        Seats: '',
        HostedPageId: '',
        CustomerId: '',
        IsTrial: false,
        SupportEmailContact: '',
        SupportTelephone: '',
        IsDeleted: false,
        TxtInvites: false,
        Roles: true,
        VideoLibrary: true,
        Intros: true,
        MeetNow: false,
        ClientPortal: true,
        Reports: true,
        Events: false,
        Notification: true,
        Scoring: false,
        Surveys: false,
        Candidates: true,
        PublicLinks: false,
        AccountGroupMaster: 0,
        ReportFrequency: 0,
        CatchAllEmail: '',
        AutomatedReporting: false,
        ReportRecipient: '',
        SupressEmails: false,
        SMSFriendlyName:'',
        Jobtrain: false,
        Meeting: true,
        Transcriptions: false
      }
      this.usersCount = 0
      this.addEditAccountForm = false
    },
    closeErrorModal () {
      this.errorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .mob {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
    margin-top: 15px;
  }
  .m-a-left {
    margin-left: 0px !important;
  }
  .italics {
    font-style:italic;
  }
  .mobileTopSections {
    margin-top: 0px !important;
  }
  @media (max-width: 639px) {
    .mob-adjust {
      margin-top: -5px;
    }
    .smFont {
      font-size: 12px !important;
    }
  }
</style>
