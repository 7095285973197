<template>
  <div>
    <mdb-modal
      id="importAndInviteModal"
      :show="importAndInviteModal"
      @close="cancel()"
    >
      <mdb-modal-header>
        <mdb-modal-title>Import & Invite</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="grey-text small text-left">
          When importing and inviting candidate data, please provide a correctly formatted CSV file
          (click <a
            href="https://go.shineinterview.com/bulk-upload-example.csv"
            target="_blank"
            class="primary-colour"
          ><strong>here</strong></a>
          to download an example) including first name, last name, email address (all other fields are optional). <br><br>
          Note: there is a limit of {{ BULK_UPLOAD_LIMIT }} records per CSV upload. <br><br><strong>Please ensure the data you are about to import is correct. You will not be able to undo this action.</strong>
          <br><br>
        </div>
        <form>
          <div class="md-form form-sm text-left" @click="launchCsvPicker">
            <i class="fas fa-upload prefix" style="margin-top: 6px;font-size: 1.3rem;cursor:pointer" />
            <input
              type="text"
              class="form-control"
              :placeholder="importText"
              :value="file.name"
              readonly
            >
            <input
              ref="file"
              v-uploader
              type="file"
              style="display:none"
              accept=".csv"
            >
          </div>
          <div
            v-if="!hideRedoStageOptionOnImportModal"
            class="md-form form-sm d-flex align-items-center ml-2"
            @click="isRedoStage = !isRedoStage"
          >
            <mdb-input
              :value="isRedoStage"
              type="checkbox"
              label="Ask existing candidates to redo this stage?"
              style="margin-left:-25px;"
              size="sm"
            />
            <div style="margin-left: -40px;margin-top: 25px;">
              <Tooltip
                class="mt-3"
                style="display: inline"
                tooltip-direction="left"
                :tool-tip-summary="!isRedoStage ? `Ignore any candidates already invited to this role` :
                  `Candidates already invited to this role will be asked to redo the stage. Choosing this option will send a new invitation to the candidate - any previously completed interviews will only be available via the candidates history.`"
              />
            </div>
          </div>
        </form>
      </mdb-modal-body>
      <mdb-modal-footer>
        <div v-if="!confirm">
          <mdb-btn
            v-show="!buttonClick"
            :disabled="file == ''"
            class="float-right"
            size="sm"
            style="width:110px;"
            @click="confirmImport()"
          >
            IMPORT
          </mdb-btn>
        </div>
        <div v-else>
          <mdb-btn
            v-show="!buttonClick"
            :disabled="file == ''"
            class="float-right"
            size="sm"
            style="width:110px;color:white !important;background-color: red !important;font-weight: bold;"
            @click="importMassCsv()"
          >
            CONFIRM
          </mdb-btn>
          <mdb-btn
            v-show="buttonClick"
            class="float-right"
            size="sm"
            style="width:110px;color:white !important;background-color: red !important;font-weight: bold;"
          >
            <mdb-icon icon="fa fa-pulse fa-spinner" />
          </mdb-btn>
        </div>
      </mdb-modal-footer>
    </mdb-modal>
    <mdb-modal
      id="genericInfoModal"
      :show="infoModal"
      @close="infoModal = false"
    >
      <mdb-modal-header>
        <mdb-modal-title>PROCESSING</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="my-4 grey-text" style="margin-top:20px !important;font-size:0.8rem;text-align:left;">
          Your import is in progress. This may take a number of minutes depending on how many records are being uploaded, whether the candidate already exists and 
          whether or not you have requested them to complete a video CV as part of the process. It can take between 1 and 10 seconds to process each candidate record.
          <br><br>
          Note: you can track any failures from notifications and from email alerts.
        </div>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn
          color="primary"
          size="sm"
          style="width:100px;"
          @click.native="closeProcessingModal"
        >
          CLOSE
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import candidateService from '@/api/services/candidate.service.js'
import { useUserData, useStatus, useCandidate } from '@/pinia'
import { storeToRefs } from 'pinia'
import Tooltip from '@/components/misc/tooltip.vue'
import { BULK_UPLOAD_LIMIT } from '@/common/config.js'

export default {
  name: 'ImportAndInviteModal',
  components: {
    Tooltip
  },
  directives: {
    uploader: {
      bind (el, binding, vnode) {
        el.addEventListener('change', e => {
          if (e.target.files[0] !== undefined) {
            vnode.context.file = e.target.files[0]
          }
        })
      }
    }
  },
  setup () {
    const userStore = useUserData()
    const { authData } = storeToRefs(userStore)

    const statusStore = useStatus()
    const { showGenericErrorModal } = statusStore

    const candidateStore = useCandidate()
    const { importAndInviteModal, importAndInviteModalRoleId, hideRedoStageOptionOnImportModal, importAndInviteModalRoleWorkflowStageId } = storeToRefs(candidateStore)

    return {
      showGenericErrorModal,
      importAndInviteModalRoleId,
      importAndInviteModal,
      hideRedoStageOptionOnImportModal,
      importAndInviteModalRoleWorkflowStageId,
      authData,
      BULK_UPLOAD_LIMIT
    }
  },
  data () {
    return {
      isRedoStage: false,
      buttonClick: false,
      import: false,
      csvText: 'Select a new cv to upload',
      file: '',
      importText: 'Select a csv file to upload',
      infoModal: false,
      confirm: false
    }
  },
  methods: {
    launchCsvPicker () {
      this.$refs.file.click()
    },
    confirmImport () {
      this.confirm = true
    },
    importMassCsv () {
      this.buttonClick = true
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('isRedoStage', this.isRedoStage)
      formData.append('jobId', this.importAndInviteModalRoleId)
      formData.append('roleWorkflowStageId', this.importAndInviteModalRoleWorkflowStageId)
      try {
        setTimeout(() => {
          candidateService.importMassCsv(formData)
          this.cancel()
          this.infoModal = true
          this.confirm = false
        }, 3000)
      } catch {
        this.showGenericErrorModal()
        this.cancel()
        this.confirm = false
      }
    },
    cancel () {
      this.file = ''
      this.$refs.file.value = null
      this.hideRedoStageOptionOnImportModal = true
      this.isRedoStage = false
      this.importAndInviteModalRoleId = 0
      this.buttonClick = false
      this.importAndInviteModal = false
    },
    closeProcessingModal() {
      this.infoModal = false
      this.$emit('updateComponentContext')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
